<template>
  <div id="addGeneralPolicy">
    <div class="page-header">
      <p class="page-path">
        政策设置<span class="slashline">/</span>通用政策设置<span
          class="slashline"
          >/</span
        >新增政策
      </p>
      <p class="page-title">新增政策</p>
    </div>
    <div class="operation-pannel">
      <el-collapse v-model="activeNames">
        <el-collapse-item name="1">
          <template slot="title">
            <div class="basic-info">基本信息</div>
          </template>
          <el-form
            :model="basicInfoForm"
            label-position="top"
            ref="basicInfoForm"
            class="basic-info-form"
            :rules="basicInfoFormRules"
          >
            <el-row :gutter="30">
              <el-col :span="8">
                <el-form-item label="所属地区" prop="region">
                  <el-select
                    v-model="basicInfoForm.region"
                    placeholder="请选择"
                    multiple
                    filterable
                    @visible-change="districtChange"
                    @remove-tag="removeDistrictTag"
                  >
                    <el-option
                      :label="item"
                      :value="item"
                      v-for="(item, index) in basicInfoForm.regionList"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="参保类型" prop="type">
                  <el-select
                    v-model="basicInfoForm.type"
                    placeholder="请选择"
                    filterable
                    @change="typeChange"
                  >
                    <el-option
                      :label="item"
                      :value="item"
                      v-for="(item, index) in basicInfoForm.typeList"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="政策名称" prop="policyName">
                  <el-input
                    v-model="basicInfoForm.policyName"
                    placeholder="请输入"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="8">
                <el-form-item label="生效月份" prop="effectiveTime">
                  <el-date-picker
                    :editable="true"
                    v-model="basicInfoForm.effectiveTime"
                    type="month"
                    placeholder="请选择"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="协议附件" prop="uploadfileName">
                  <el-button
                    type="default"
                    icon="el-icon-upload2"
                    @click="$refs.uploadfile.click()"
                    :loading="uploadingFile"
                    >上传文件</el-button
                  >
                  <span class="uploadfileName">{{
                    basicInfoForm.uploadfileName
                  }}</span>
                  <el-button
                    v-if="basicInfoForm.uploadfileName != ''"
                    style="margin-left: 8px"
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    circle
                    @click="
                      basicInfoForm.uploadfile = '';
                      basicInfoForm.uploadfileName = '';
                      basicInfoForm.attachAddress = '';
                      $refs.uploadfile.value = '';
                    "
                  ></el-button>
                  <p style="color: #999">
                    支持拓展名：.rar .zip .doc .docx .pdf .jpg...
                  </p>
                  <input
                    hidden
                    type="file"
                    ref="uploadfile"
                    @change="uploadfileChange($event)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-collapse-item>
      </el-collapse>
      <div class="policy-info">
        <el-tabs v-model="activeCard" type="card">
          <el-tab-pane label="正常" name="正常">
            <div class="control-panel">
              <div>
                政策信息<el-button
                  type="primary"
                  style="margin-left: 20px"
                  @click="addNormalPolicyRow"
                  >新增</el-button
                ><el-button type="primary" plain @click="downloadDemo(0)"
                  >模板下载</el-button
                ><el-button
                  type="default"
                  @click="$refs.multiImportFileNormal.click()"
                  :loading="multiImporting"
                  >批量导入</el-button
                >
                <input
                  type="file"
                  hidden
                  ref="multiImportFileNormal"
                  @change="multiImportFileChange($event, 0)"
                />
              </div>
              <el-button
                type="default"
                icon="el-icon-plus"
                @click="linkToFanyou"
                >关联大库政策</el-button
              >
            </div>
            <el-form ref="normalPolicyForm" :model="normalPolicyForm">
              <el-table
                ref="normalPolicyTable"
                max-height="500"
                :data="normalPolicyForm.normalPolicyData"
                border
              >
                <el-table-column type="selection" width="48"> </el-table-column>
                <el-table-column label="险种类别" width="110">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'normalPolicyData.' + scope.$index + '.type'"
                      :rules="{
                        required: true,
                        message: '请选择险种类别',
                        trigger: 'change',
                      }"
                    >
                      <el-select
                        v-model="scope.row.type"
                        placeholder="请选择"
                        @change="
                          (val) =>
                            normalCategoryChange(val, scope.row, scope.$index)
                        "
                      >
                        <el-option label="社保" :value="0"></el-option>
                        <el-option label="公积金" :value="1"></el-option>
                        <el-option label="自定义险种" :value="2"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="险种" width="140">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'normalPolicyData.' + scope.$index + '.name'"
                      :rules="{
                        required: true,
                        message: '请选择险种',
                        trigger: 'change',
                      }"
                    >
                      <el-select
                        v-model="scope.row.name"
                        placeholder="请选择"
                        v-if="scope.row.type == '0'"
                      >
                        <el-option
                          label="医疗保险"
                          value="医疗保险"
                        ></el-option>
                        <el-option
                          label="养老保险"
                          value="养老保险"
                        ></el-option>
                        <el-option
                          label="生育保险"
                          value="生育保险"
                        ></el-option>
                        <el-option
                          label="工伤保险"
                          value="工伤保险"
                        ></el-option>
                        <el-option
                          label="失业保险"
                          value="失业保险"
                        ></el-option>
                        <el-option
                          label="大病/补充/大额医疗"
                          value="大病/补充/大额医疗"
                        ></el-option>
                        <el-option label="残保金" value="残保金"></el-option>
                      </el-select>
                      <el-select
                        v-model="scope.row.name"
                        placeholder="请选择"
                        v-if="scope.row.type == '1'"
                      >
                        <el-option label="公积金" value="公积金"></el-option>
                      </el-select>
                      <el-select
                        v-model="scope.row.name"
                        placeholder="先选险种类别"
                        v-if="scope.row.type != '0' && scope.row.type != '1'"
                        :disabled="true"
                      >
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="单位部分">
                  <el-table-column
                    label="基数上限"
                    prop="enterpriseNumberCeiling"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'normalPolicyData.' +
                          scope.$index +
                          '.enterpriseNumberCeiling'
                        "
                        :rules="[
                          {
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                          },
                          {
                            pattern: /^\d+(\.\d+)?$/,
                            message: '须填>=0数字',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          type="number"
                          v-model.number="scope.row.enterpriseNumberCeiling"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="基数下限" width="100">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'normalPolicyData.' +
                          scope.$index +
                          '.enterpriseNumberFloor'
                        "
                        :rules="[
                          {
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                          },
                          {
                            pattern: /^\d+(\.\d+)?$/,
                            message: '须填>=0数字',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          type="number"
                          v-model.number="scope.row.enterpriseNumberFloor"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="缴纳比例%" width="120">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'normalPolicyData.' +
                          scope.$index +
                          '.enterprisePercent'
                        "
                        :rules="[
                          {
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                          },
                          {
                            pattern: /^(([1-9][0-9]|[0-9])(\.\d{1,4})?|0|0\.\d{1,4}|100)$/,
                            message: '须填[0,100]数字，最多四位小数',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          type="number"
                          v-model.number="scope.row.enterprisePercent"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="最低缴费金额" width="110">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'normalPolicyData.' +
                          scope.$index +
                          '.enterpriseMinMoney'
                        "
                      >
                        <el-input
                          v-model.number="scope.row.enterpriseMinMoney"
                          :disabled="true"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="个人部分">
                  <el-table-column label="基数上限" width="100">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'normalPolicyData.' +
                          scope.$index +
                          '.personNumberCeiling'
                        "
                        :rules="[
                          {
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                          },
                          {
                            pattern: /^\d+(\.\d+)?$/,
                            message: '须填>=0数字',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          type="number"
                          v-model.number="scope.row.personNumberCeiling"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="基数下限" width="100">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'normalPolicyData.' +
                          scope.$index +
                          '.personNumberFloor'
                        "
                        :rules="[
                          {
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                          },
                          {
                            pattern: /^\d+(\.\d+)?$/,
                            message: '须填>=0数字',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          type="number"
                          v-model.number="scope.row.personNumberFloor"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="缴纳比例%" width="120">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'normalPolicyData.' + scope.$index + '.personPercent'
                        "
                        :rules="[
                          {
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                          },
                          {
                            pattern: /^(([1-9][0-9]|[0-9])(\.\d{1,4})?|0|0\.\d{1,4}|100)$/,
                            message: '须填[0,100]数字',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          type="number"
                          v-model.number="scope.row.personPercent"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="最低缴费金额" width="110">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'normalPolicyData.' + scope.$index + '.personMinMoney'
                        "
                      >
                        <el-input
                          v-model.number="scope.row.personMinMoney"
                          :disabled="true"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="小数位数（单位）" width="100">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'normalPolicyData.' +
                        scope.$index +
                        '.enterpriseBitNumber'
                      "
                      :rules="[
                        {
                          required: true,
                          message: '请输入',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^\d+(\.\d+)?$/,
                          message: '须填>=0数字',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model.number="scope.row.enterpriseBitNumber"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="取值规则（单位）" width="120">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'normalPolicyData.' +
                        scope.$index +
                        '.enterpriseValueRule'
                      "
                      :rules="{
                        required: true,
                        message: '请选择',
                        trigger: 'change',
                      }"
                    >
                      <el-select
                        v-model="scope.row.enterpriseValueRule"
                        placeholder="请选择"
                      >
                        <el-option label="四舍五入" :value="1"></el-option>
                        <el-option label="向上取整" :value="2"></el-option>
                        <el-option label="向下取整" :value="3"></el-option>
                        <el-option label="见角进元" :value="4"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="小数位数（个人）" width="100">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'normalPolicyData.' + scope.$index + '.personBitNumber'
                      "
                      :rules="[
                        {
                          required: true,
                          message: '请输入',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^\d+(\.\d+)?$/,
                          message: '须为>=0数字',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model.number="scope.row.personBitNumber"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="取值规则（个人）" width="120">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'normalPolicyData.' + scope.$index + '.personValueRule'
                      "
                      :rules="{
                        required: true,
                        message: '请选择',
                        trigger: 'change',
                      }"
                    >
                      <el-select
                        v-model="scope.row.personValueRule"
                        placeholder="请选择"
                      >
                        <el-option label="四舍五入" :value="1"></el-option>
                        <el-option label="向上取整" :value="2"></el-option>
                        <el-option label="向下取整" :value="3"></el-option>
                        <el-option label="见角进元" :value="4"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="缴费周期" width="120">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'normalPolicyData.' + scope.$index + '.payType'"
                      :rules="{
                        required: true,
                        message: '请选择',
                        trigger: 'change',
                      }"
                    >
                      <el-select
                        v-model="scope.row.payType"
                        placeholder="请选择"
                      >
                        <el-option label="按月" :value="0"></el-option>
                        <el-option label="按年" :value="1"></el-option>
                        <el-option label="按年+首次全额" :value="2"></el-option>
                        <el-option label="按年+首次余额" :value="3"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="年费收取月份" width="120">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'normalPolicyData.' + scope.$index + '.payMonth'"
                      :rules="[
                        {
                          required: scope.row.payType != 0,
                          message: '请输入',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^([1-9]?|1[0-2])$/,
                          message: '必须>0且<13整数',
                          trigger: 'blur',
                        }
                      ]"
                    >
                      <el-input
                        v-model="scope.row.payMonth"
                        :disabled="scope.row.payType ==0"
                        placeholder="月份"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="启用月份" width="140">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'normalPolicyData.' + scope.$index + '.startMonth'"
                      :rules="{
                        required: true,
                        message: '请选择',
                        trigger: 'change',
                      }"
                    >
                      <el-date-picker
                        :editable="false"
                        v-model="scope.row.startMonth"
                        value-format="yyyy-MM"
                        type="month"
                        placeholder="请选择"
                        style="width: 110px"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="备注">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'normalPolicyData.' + scope.$index + '.remark'"
                      :rules="{
                        required: false,
                        message: '请输入',
                        trigger: 'change',
                      }"
                    >
                      <el-input v-model="scope.row.remark"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button type="primary" @click="delNormalPolicy(scope)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="补缴" name="补缴">
            <div class="control-panel">
              <div>
                政策信息<el-button
                  type="primary"
                  style="margin-left: 20px"
                  @click="addMakeupPolicyRow"
                  >新增</el-button
                ><el-button type="primary" plain @click="downloadDemo(1)"
                  >模板下载</el-button
                ><el-button
                  type="default"
                  @click="$refs.multiImportFileMakeup.click()"
                  :loading="multiImporting"
                  >批量导入</el-button
                >
                <input
                  type="file"
                  hidden
                  ref="multiImportFileMakeup"
                  @change="multiImportFileChange($event, 1)"
                />
              </div>
              <el-button
                type="default"
                icon="el-icon-plus"
                @click="linkToFanyou"
                >关联大库政策</el-button
              >
            </div>
            <el-form ref="makeupPolicyForm" :model="makeupPolicyForm">
              <el-table
                ref="makeupPolicyTable"
                max-height="500"
                :data="makeupPolicyForm.makeupPolicyData"
                border
              >
                <el-table-column type="selection" width="48"> </el-table-column>
                <el-table-column label="险种类别" width="110">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'makeupPolicyData.' + scope.$index + '.type'"
                      :rules="{
                        required: true,
                        message: '请选择险种类别',
                        trigger: 'change',
                      }"
                    >
                      <el-select
                        v-model="scope.row.type"
                        placeholder="请选择"
                        @change="
                          (val) =>
                            makeupCategoryChange(val, scope.row, scope.$index)
                        "
                      >
                        <el-option label="社保" :value="0"></el-option>
                        <el-option label="公积金" :value="1"></el-option>
                        <el-option label="自定义险种" :value="2"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="险种" width="140">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'makeupPolicyData.' + scope.$index + '.name'"
                      :rules="{
                        required: true,
                        message: '请选择险种',
                        trigger: 'change',
                      }"
                    >
                      <el-select
                        v-model="scope.row.name"
                        placeholder="请选择"
                        v-if="scope.row.type == '0'"
                      >
                        <el-option
                          label="医疗保险"
                          value="医疗保险"
                        ></el-option>
                        <el-option
                          label="养老保险"
                          value="养老保险"
                        ></el-option>
                        <el-option
                          label="生育保险"
                          value="生育保险"
                        ></el-option>
                        <el-option
                          label="工伤保险"
                          value="工伤保险"
                        ></el-option>
                        <el-option
                          label="失业保险"
                          value="失业保险"
                        ></el-option>
                        <el-option
                          label="大病/补充/大额医疗"
                          value="大病/补充/大额医疗"
                        ></el-option>
                        <el-option label="残保金" value="残保金"></el-option>
                      </el-select>
                      <el-select
                        v-model="scope.row.name"
                        placeholder="请选择"
                        v-if="scope.row.type == '1'"
                      >
                        <el-option label="公积金" value="公积金"></el-option>
                      </el-select>
                      <el-select
                        v-model="scope.row.name"
                        placeholder="先选险种类别"
                        v-if="scope.row.type != '0' && scope.row.type != '1'"
                        :disabled="true"
                      >
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="跨周期补缴" width="110">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'makeupPolicyData.' + scope.$index + '.isPeriod'"
                      :rules="{
                        required: true,
                        message: '请选择',
                        trigger: 'change',
                      }"
                    >
                      <el-select
                        v-model="scope.row.isPeriod"
                        placeholder="请选择"
                      >
                        <el-option label="允许" :value="0"></el-option>
                        <el-option label="不允许" :value="1"></el-option>
                        <!-- <el-option label="残保金" value="残保金"></el-option> -->
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="周期起始时间" width="188">
                  <template slot-scope="scope">
                    <div v-if="scope.row.isPeriod == 1">
                      <el-form-item
                        :prop="
                          'makeupPolicyData.' + scope.$index + '.cycleStartYear'
                        "
                        :rules="{
                          required: true,
                          message: '请选择',
                          trigger: 'change',
                        }"
                      >
                        <el-select
                          v-model="scope.row.cycleStartYear"
                          placeholder="请选择"
                          style="width: 90px"
                        >
                          <el-option label="上年" value="上年"></el-option>
                          <el-option label="当年" value="当年"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        :prop="
                          'makeupPolicyData.' +
                          scope.$index +
                          '.cycleStartMonth'
                        "
                        :rules="{
                          required: true,
                          message: '请输入',
                          trigger: 'change',
                        }"
                      >
                        <el-input
                          v-model="scope.row.cycleStartMonth"
                          controls-position="right"
                          type="number"
                          placeholder="请输入"
                          :min="1"
                          :max="12"
                          style="width: 80px"
                          :controls="false"
                        ></el-input>
                        月
                      </el-form-item>
                    </div>
                    <div v-if="scope.row.isPeriod == 0">不限</div>
                  </template>
                </el-table-column>
                <el-table-column label="周期截止时间" width="188">
                  <template slot-scope="scope">
                    <div v-if="scope.row.isPeriod == 1">
                      <el-form-item
                        :prop="
                          'makeupPolicyData.' + scope.$index + '.cycleEndYear'
                        "
                        :rules="{
                          required: true,
                          message: '请选择',
                          trigger: 'change',
                        }"
                      >
                        <el-select
                          v-model="scope.row.cycleEndYear"
                          placeholder="请选择"
                          style="width: 90px"
                        >
                          <el-option label="上年" value="上年"></el-option>
                          <el-option label="当年" value="当年"></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        :prop="
                          'makeupPolicyData.' + scope.$index + '.cycleEndMonth'
                        "
                        :rules="{
                          required: true,
                          message: '请选择',
                          trigger: 'change',
                        }"
                      >
                        <el-input
                          v-model="scope.row.cycleEndMonth"
                          controls-position="right"
                          type="number"
                          placeholder="请输入"
                          :min="1"
                          :max="12"
                          style="width: 80px"
                          :controls="false"
                        ></el-input>
                        月
                      </el-form-item>
                    </div>
                    <div v-if="scope.row.isPeriod == 0">不限</div>
                  </template>
                </el-table-column>
                <el-table-column label="单位部分">
                  <el-table-column
                    label="基数上限"
                    prop="enterpriseNumberCeiling"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'makeupPolicyData.' +
                          scope.$index +
                          '.enterpriseNumberCeiling'
                        "
                        :rules="[
                          {
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                          },
                          {
                            pattern: /^\d+(\.\d+)?$/,
                            message: '须填>=0数字',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          type="number"
                          v-model.number="scope.row.enterpriseNumberCeiling"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="基数下限" width="100">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'makeupPolicyData.' +
                          scope.$index +
                          '.enterpriseNumberFloor'
                        "
                        :rules="[
                          {
                            required: true,
                            message: '请输入',
                            trigger: 'change',
                          },
                          {
                            pattern: /^\d+(\.\d+)?$/,
                            message: '须填>=0数字',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          type="number"
                          v-model.number="scope.row.enterpriseNumberFloor"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="缴纳比例%" width="120">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'makeupPolicyData.' +
                          scope.$index +
                          '.enterprisePercent'
                        "
                        :rules="[
                          {
                            required: true,
                            message: '请输入',
                            trigger: 'change',
                          },
                          {
                            pattern: /^(([1-9][0-9]|[0-9])(\.\d{1,4})?|0|0\.\d{1,4}|100)$/,
                            message: '须填[0,100]数字',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          type="number"
                          v-model.number="scope.row.enterprisePercent"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="最低缴费金额" width="110">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'makeupPolicyData.' +
                          scope.$index +
                          '.enterpriseMinMoney'
                        "
                      >
                        <el-input
                          v-model.number="scope.row.enterpriseMinMoney"
                          :disabled="true"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="个人部分">
                  <el-table-column label="基数上限" width="100">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'makeupPolicyData.' +
                          scope.$index +
                          '.personNumberCeiling'
                        "
                        :rules="[
                          {
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                          },
                          {
                            pattern: /^\d+(\.\d+)?$/,
                            message: '须填>=0数字',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          type="number"
                          v-model.number="scope.row.personNumberCeiling"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="基数下限" width="100">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'makeupPolicyData.' +
                          scope.$index +
                          '.personNumberFloor'
                        "
                        :rules="[
                          {
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                          },
                          {
                            pattern: /^\d+(\.\d+)?$/,
                            message: '须填>=0数字',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          type="number"
                          v-model.number="scope.row.personNumberFloor"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="缴纳比例%" width="120">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'makeupPolicyData.' + scope.$index + '.personPercent'
                        "
                        :rules="[
                          {
                            required: true,
                            message: '请输入',
                            trigger: 'blur',
                          },
                          {
                            pattern: /^(([1-9][0-9]|[0-9])(\.\d{1,4})?|0|0\.\d{1,4}|100)$/,
                            message: '须填[0,100]数字',
                            trigger: 'blur',
                          },
                        ]"
                      >
                        <el-input
                          type="number"
                          v-model.number="scope.row.personPercent"
                          placeholder="请输入"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                  <el-table-column label="最低缴费金额" width="110">
                    <template slot-scope="scope">
                      <el-form-item
                        :prop="
                          'makeupPolicyData.' + scope.$index + '.personMinMoney'
                        "
                      >
                        <el-input
                          v-model.number="scope.row.personMinMoney"
                          :disabled="true"
                        ></el-input>
                      </el-form-item>
                    </template>
                  </el-table-column>
                </el-table-column>
                <el-table-column label="小数位数（单位）" width="100">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'makeupPolicyData.' +
                        scope.$index +
                        '.enterpriseBitNumber'
                      "
                      :rules="[
                        {
                          required: true,
                          message: '请输入',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^\d+(\.\d+)?$/,
                          message: '须填>=0数字',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model.number="scope.row.enterpriseBitNumber"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="取值规则（单位）" width="120">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'makeupPolicyData.' +
                        scope.$index +
                        '.enterpriseValueRule'
                      "
                      :rules="[
                        {
                          required: true,
                          message: '请选择',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-select
                        v-model="scope.row.enterpriseValueRule"
                        placeholder="请选择"
                      >
                        <el-option label="四舍五入" :value="1"></el-option>
                        <el-option label="向上取整" :value="2"></el-option>
                        <el-option label="向下取整" :value="3"></el-option>
                        <el-option label="见角进元" :value="4"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="小数位数（个人）" width="100">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'makeupPolicyData.' + scope.$index + '.personBitNumber'
                      "
                      :rules="[
                        {
                          required: true,
                          message: '请输入',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^\d+(\.\d+)?$/,
                          message: '须填>=0数字',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model.number="scope.row.personBitNumber"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="取值规则（个人）" width="120">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'makeupPolicyData.' + scope.$index + '.personValueRule'
                      "
                      :rules="[
                        {
                          required: true,
                          message: '请选择',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-select
                        v-model="scope.row.personValueRule"
                        placeholder="请选择"
                      >
                        <el-option label="四舍五入" :value="1"></el-option>
                        <el-option label="向上取整" :value="2"></el-option>
                        <el-option label="向下取整" :value="3"></el-option>
                        <el-option label="见角进元" :value="4"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="缴费周期" width="120">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'makeupPolicyData.' + scope.$index + '.payType'"
                      :rules="[
                        {
                          required: true,
                          message: '请选择',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-select
                        v-model="scope.row.payType"
                        placeholder="请选择"
                      >
                        <el-option label="按月" :value="0"></el-option>
                        <el-option label="按年" :value="1"></el-option>
                        <el-option label="按年+首次全额" :value="2"></el-option>
                        <el-option label="按年+首次余额" :value="3"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="年费收取月份" width="120">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'makeupPolicyData.' + scope.$index + '.payMonth'"
                      :rules="[
                        {
                          required: scope.row.payType!=0,
                          message: '请输入',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^([1-9]?|1[0-2])$/,
                          message: '必须>0且<13整数',
                          trigger: 'blur',
                        }
                      ]"
                    >
                      <el-input
                        :disabled="scope.row.payType ==0"
                        v-model="scope.row.payMonth"
                        placeholder="月份"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="启用月份" width="140">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="'makeupPolicyData.' + scope.$index + '.startMonth'"
                      :rules="[
                        {
                          required: true,
                          message: '请选择',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-date-picker
                        :editable="false"
                        v-model="scope.row.startMonth"
                        value-format="yyyy-MM"
                        type="month"
                        placeholder="请选择"
                        style="width: 110px"
                      >
                      </el-date-picker>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="补缴月数" width="100">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'makeupPolicyData.' + scope.$index + '.repairMonth'
                      "
                      :rules="[
                        {
                          required: true,
                          message: '请输入',
                          trigger: 'blur',
                        },
                        {
                          pattern: /^\d+(\.\d+)?$/,
                          message: '须填>=0数字',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input
                        v-model.number="scope.row.repairMonth"
                        placeholder="请输入"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="备注" width="140">
                  <template slot-scope="scope">
                    <el-form-item
                      :prop="
                        'makeupPolicyData.' + scope.$index + '.remark'
                      "
                      :rules="[
                        {
                          required: false,
                          message: '请输入',
                          trigger: 'change',
                        },
                      ]"
                    >
                      <el-input v-model="scope.row.remark"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button type="primary" @click="delMakeupPolicy(scope)"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="bottom-btn-group">
        <el-button @click="returnGeneralPolicyList">取消</el-button>
        <el-button type="primary" @click="SubmitForm()" :loading="savingPolicy"
          >保存</el-button
        >
      </div>
    </div>
    <!-- 大库关联 -->
    <el-dialog 
      width="30%"
      title="关联政策"
      :visible.sync="fanyouStorageDialog"
      @closed="fanyouStoragePolicyData = []"
    >
      <el-form
        :inline="true"
        ref="fanyouStorageFilterForm"
        :model="fanyouStorageFilterForm"
      >
        <el-form-item label="政策名称：" prop="policyName">
          <el-input
            v-model="fanyouStorageFilterForm.policyName"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getFanyouSocialPolicyList(0)"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        ref="fanyouStoragePolicyTable"
        max-height="380"
        :data="fanyouStoragePolicyData"
        border
        v-loading="fanyouStoragePolicyTableLoading"
        :row-key="getRowKeys"
      >
        <el-table-column type="selection" width="48" :reserve-selection="true">
        </el-table-column>
        <el-table-column
          property="policyVersionId"
          label="政策版本号"
        ></el-table-column>
        <el-table-column
          property="policyName"
          label="政策名称"
        ></el-table-column>
      </el-table>
      <el-pagination v-if="pageshow"
        background
        @current-change="fanyouPolicyCurrentChange"
        :total="fanyouPolicyTotalCount"
        layout="total, prev, pager, next"
        style="margin-top: 16px"
      >
      </el-pagination>
      <div slot="footer" class="dialog-footer">
        <el-button @click="fanyouStorageDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="getFanyouPolicySelected"
          :loading="linkingTotable"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AddNetwork",
  components: {},
  data: function () {
    return {
      pageshow:true,
      activeNames: ["1"],
      selectedRegion: [],
      basicInfoForm: {
        policyName: "",
        region: [],
        regionList: [],
        type: "",
        typeList: [], // 根据城市维护的参保类型list
        effectiveTime: "",
        uploadFile: "",
        uploadfileName: "",
        attachAddress: "",
      },
      basicInfoFormRules: {
        region: [
          { required: true, message: "请选择所属地区", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择参保类型", trigger: "change" },
        ],
        effectiveTime: [
          { required: true, message: "请选择生效月份", trigger: "change" },
        ],
      },
      activeCard: "正常",
      normalPolicyForm: {
        normalPolicyData: [], // 正常--政策信息表
      },
      makeupPolicyForm: {
        makeupPolicyData: [], // 补缴--政策信息表
      },
      savingPolicy: false, // 保存
      uploadingFile: false, // 上传协议
      multiImporting: false, // 批量导入
      fanyouStorageDialog: false,
      fanyouStorageFilterForm: {
        policyVersionId:"",
        policyName: "",
      },
      fanyouStoragePolicyData: [],
      fanyouStoragePolicyTableLoading: false,
      fanyouPolicyCurrentPage: 1,
      fanyouPolicyTotalCount: 0,
      linkingTotable: false,
    };
  },
  watch: {
    "normalPolicyForm.normalPolicyData": {
      handler: function (newValue, oldValue) {
          newValue.forEach((tag, index)=>{
            let oldtag = oldValue[index];
            if(tag.payType == 0){
             tag.payMonth = ""; 
            }
            //console.log(tag.enterpriseNumberCeiling + "," + oldtag.enterpriseNumberCeiling + ":" + tag.enterpriseNumberFloor + "," + oldtag.enterpriseNumberFloor + ":"  + tag.enterprisePercent + "," + oldtag.enterprisePercent + ":" + tag.enterpriseBitNumber + "," + oldtag.enterpriseBitNumber + ":"+ tag.enterpriseValueRule + "," + oldtag.enterpriseValueRule + ";");
            if(1||tag.enterpriseNumberCeiling != oldtag.enterpriseNumberCeiling || tag.enterpriseNumberFloor != oldtag.enterpriseNumberFloor || tag.enterprisePercent != oldtag.enterprisePercent || tag.enterpriseBitNumber != oldtag.enterpriseBitNumber || tag.enterpriseValueRule != oldtag.enterpriseValueRule)
            {
              if(tag.enterpriseNumberCeiling!="" && tag.enterpriseNumberFloor!="" && tag.enterprisePercent!="" && tag.enterpriseValueRule!=""  && (tag.enterpriseBitNumber!= "" || tag.enterpriseBitNumber === 0) ){
                let emoney = this.accMul(tag.enterpriseNumberFloor.toString(),tag.enterprisePercent.toString())
                if(tag.enterpriseValueRule == 1) {
                  tag.enterpriseMinMoney = this.format_round(emoney, tag.enterpriseBitNumber)
                }
                if(tag.enterpriseValueRule == 2) {
                  tag.enterpriseMinMoney = this.format_cell(emoney, tag.enterpriseBitNumber)
                }
                if(tag.enterpriseValueRule == 3) {
                  tag.enterpriseMinMoney = this.format_floor(emoney, tag.enterpriseBitNumber)
                }
                if(tag.enterpriseValueRule == 4) {
                  tag.enterpriseMinMoney = this.format_Angle(emoney, tag.enterpriseBitNumber)
                }
              }
            } else {
              tag.enterpriseMinMoney = 0;
            }
            if(1||tag.personNumberCeiling != oldtag.personNumberCeiling || tag.personNumberFloor != oldtag.personNumberFloor || tag.personPercent != oldtag.personPercent || tag.personBitNumber != oldtag.personBitNumber || tag.personValueRule != oldtag.personValueRule)
            {
              if(tag.personNumberCeiling!="" && tag.personNumberFloor!="" && tag.personPercent!="" && tag.personValueRule!=""  && (tag.personBitNumber!="" || tag.personBitNumber === 0)){
                let pmoney = this.accMul(tag.personNumberFloor.toString(),tag.personPercent.toString())
                if(tag.personValueRule == 1) {
                  tag.personMinMoney = this.format_round(pmoney, tag.personBitNumber)
                }
                if(tag.personValueRule == 2) {
                  tag.personMinMoney = this.format_cell(pmoney, tag.personBitNumber)
                }
                if(tag.personValueRule == 3) {
                  tag.personMinMoney = this.format_floor(pmoney, tag.personBitNumber)
                }
                if(tag.personValueRule == 4) {
                  tag.personMinMoney = this.format_Angle(pmoney, tag.personBitNumber)
                }
              }
            } else {
              tag.personMinMoney = 0;
            }
          }
        );

      },
      deep: true,
    },
    "makeupPolicyForm.makeupPolicyData": {
      handler: function (newValue, oldValue) {
        // console.log(newValue);
        // console.log(oldValue);
        newValue.forEach((tag, index) => {
          let oldtag = oldValue[index];
          if(tag.payType == 0){
             tag.payMonth = ""; 
            }
          //console.log(tag.enterpriseNumberCeiling + "," + oldtag.enterpriseNumberCeiling + ":" + tag.enterpriseNumberFloor + "," + oldtag.enterpriseNumberFloor + ":"  + tag.enterprisePercent + "," + oldtag.enterprisePercent + ":" + tag.enterpriseBitNumber + "," + oldtag.enterpriseBitNumber + ":"+ tag.enterpriseValueRule + "," + oldtag.enterpriseValueRule + ";");
          if (
            1 ||
            tag.enterpriseNumberCeiling != oldtag.enterpriseNumberCeiling ||
            tag.enterpriseNumberFloor != oldtag.enterpriseNumberFloor ||
            tag.enterprisePercent != oldtag.enterprisePercent ||
            tag.enterpriseBitNumber != oldtag.enterpriseBitNumber ||
            tag.enterpriseValueRule != oldtag.enterpriseValueRule
          ) {
            if (
              tag.enterpriseNumberCeiling != "" &&
              tag.enterpriseNumberFloor != "" &&
              tag.enterprisePercent != "" &&
              tag.enterpriseValueRule != "" &&
              (tag.enterpriseBitNumber != "" || tag.enterpriseBitNumber === 0)
            ) {
              let emoney = this.accMul(
                tag.enterpriseNumberFloor.toString(),
                tag.enterprisePercent.toString()
              );
              console.log(emoney);
              if (tag.enterpriseValueRule == 1) {
                tag.enterpriseMinMoney = this.format_round(
                  emoney,
                  tag.enterpriseBitNumber
                );
              }
              if (tag.enterpriseValueRule == 2) {
                tag.enterpriseMinMoney = this.format_cell(
                  emoney,
                  tag.enterpriseBitNumber
                );
              }
              if (tag.enterpriseValueRule == 3) {
                tag.enterpriseMinMoney = this.format_floor(
                  emoney,
                  tag.enterpriseBitNumber
                );
              }
              if (tag.enterpriseValueRule == 4) {
                tag.enterpriseMinMoney = this.format_Angle(
                  emoney,
                  tag.enterpriseBitNumber
                );
              }
            } else {
              tag.enterpriseMinMoney = 0;
            }
          } else {
              tag.enterpriseMinMoney = 0;
            }
          if (
            1 ||
            tag.personNumberCeiling != oldtag.personNumberCeiling ||
            tag.personNumberFloor != oldtag.personNumberFloor ||
            tag.personPercent != oldtag.personPercent ||
            tag.personBitNumber != oldtag.personBitNumber ||
            tag.personValueRule != oldtag.personValueRule
          ) {
            if (
              tag.personNumberCeiling != "" &&
              tag.personNumberFloor != "" &&
              tag.personPercent != "" &&
              tag.personValueRule != "" &&
              (tag.personBitNumber != "" || tag.personBitNumber === 0)
            ) {
              let pmoney = this.accMul(
                tag.personNumberFloor.toString(),
                tag.personPercent.toString()
              );
              if (tag.personValueRule == 1) {
                tag.personMinMoney = this.format_round(
                  pmoney,
                  tag.personBitNumber
                );
              }
              if (tag.personValueRule == 2) {
                tag.personMinMoney = this.format_cell(
                  pmoney,
                  tag.personBitNumber
                );
              }
              if (tag.personValueRule == 3) {
                tag.personMinMoney = this.format_floor(
                  pmoney,
                  tag.personBitNumber
                );
              }
              if (tag.personValueRule == 4) {
                tag.personMinMoney = this.format_Angle(
                  pmoney,
                  tag.personBitNumber
                );
              }
            } else {
              tag.personMinMoney = 0;
            }
          }else {
              tag.personMinMoney = 0;
            }
        });
      },
      deep: true,
    },
  },
  methods: {
    //n是要保留的位数 根据小数点位数 四舍五入
    accMul(num1, num2) {
      let m = 2;
      let s1 = num1.toString();
      let s2 = num2.toString();
      if (s1.split(".").length > 1) {
        m += s1.split(".")[1].length;
      }
      if (s2.split(".").length > 1) {
        m += s2.split(".")[1].length;
      }
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    accAdd(num1, num2) {
      let r1, r2, m;
      try {
        r1 = num1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }

      try {
        r2 = num2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }

      m = Math.pow(10, Math.max(r1, r2));
      return Math.round(num1 * m + num2 * m) / m;
    },
    format_round(number, n) {
      let lit = Math.pow(10, n);
      let reset = this.accMul(number, lit) * Math.pow(10, 2);
      let str = reset.toString().split(".");
      if (str[1] != undefined) {
        let temp = Number("0." + str[1]);
        if (Math.round(temp) > 0) {
          let point = "0.";
          for (var i = 0; i < n; i++) {
            if (i == n - 1) {
              point += "1";
            } else {
              point += "0";
            }
          }
          if (n == 0) {
            return number.toFixed(n);
          }
          return (Number(str[0]) / lit + Number(point)).toFixed(n);
        } else {
          return (Number(str[0]) / lit).toFixed(Number(n));
        }
      } else {
        return (Number(str[0]) / lit).toFixed(Number(n));
      }
    },
    format_cell(number, n) {
      let lit = Math.pow(10, n);
      let str = number.toString().split(".");
      if (n == 0) {
        if (str.length == 1) {
          return Number(str[0]);
        } else {
          return this.accAdd(str[0], 1);
        }
      } else {
        if (str[1] != undefined) {
          let point = "0.";
          for (var i = 0; i <= n; i++) {
            if (i == n - 1) {
              point += "1";
            } else {
              point += "0";
            }
          }
          let results = Number(str[0]);
          if (str[1].substring(n) > 0 && str[1].substring(n) != "") {
            let pointResult = this.accAdd(
              Number(str[1].substring(0, n)) / lit,
              point
            );
            return this.accAdd(Number(results), pointResult);
          } else {
            //return (Number(str[0]) + Number(str[1].substring(0, n)) / Math.pow(10, str[1].length)).toFixed(n);
            if (str[1].length == n) {
              return this.accAdd(
                Number(results),
                Number(str[1].substring(0, n)) / lit
              );
            } else {
              return this.accAdd(
                Number(results),
                Number(str[1].substring(0, n)) / Math.pow(10, str[1].length)
              );
            }
          }
        } else {
          return number.toFixed(n);
        }
      }
    },
    format_floor(number, n) {
      let lit = Math.pow(10, n);
      let str = number.toString().split(".");
      if (str[1] != undefined) {
        if (str[1].length >= n) {
          return (
            Number(str[0]) +
            Number(str[1].substring(0, n)) / lit
          ).toFixed(n);
        } else {
          return (
            Number(str[0]) +
            Number(str[1].substring(0, n)) / Math.pow(10, str[1].length)
          ).toFixed(n);
        }
      } else {
        return number.toFixed(n);
      }
    },
    format_Angle(number, n) {
      let str = number.toString().split(".");
      if (str[1] != undefined) {
        if (str[1].charAt(n) == 0) {
          return Number(str[0] + "." + str[1].substring(0, n)).toFixed(n);
        } else {
          if (n == 0) {
            return Number(Number(str[0]) + 1).toFixed(n);
          } else {
            return (
              Number(str[0] + "." + str[1].substring(0, n)) +
              Number(1 / Math.pow(10, str[1].substring(0, n).length))
            ).toFixed(n);
          }
        }
      } else {
        return Number(str[0]);
      }
    },
    getFanyouPolicySelected() {
      let policies = this.$refs["fanyouStoragePolicyTable"].selection;
      if(policies.length>1){
        this.$message.error("关联政策不允许多选！");
      }else{
        this.getFanyouPolicyDetail(policies);
      }
    },
    async getFanyouPolicyDetail(policies) {
      let reqList = [];
      policies.forEach((item) => {
        let req = this.$api.policy.fanyouSocialPolicyDetail(item.policyId);
        reqList.push(req);
      });
      this.linkingTotable = true;
      try {
        let res = await Promise.all(reqList);
        if (res) {
          let normalFanyouPolicies = [];
          let unnormalFanyouPolicies = [];
          res.forEach((item) => {
            if (item) {
              item.data.forEach((data) => {
                if (data.isNormal) {
                  normalFanyouPolicies.push(data);
                } else {
                  unnormalFanyouPolicies.push(data);
                }
              });
            }
          });
          this.normalPolicyForm.normalPolicyData = this.normalPolicyForm.normalPolicyData.concat(
            normalFanyouPolicies
          );
          this.makeupPolicyForm.makeupPolicyData = this.makeupPolicyForm.makeupPolicyData.concat(
            unnormalFanyouPolicies
          );
          this.fanyouStorageDialog = false;
        } else {
          this.$message.error("关联大库失败");
        }
        this.linkingTotable = false;
      } catch (e) {
        console.log(e);
        this.$message.error("关联大库失败");
        this.linkingTotable = false;
      }
    },
    getRowKeys(row) {
      return row.policyId;
    },
    fanyouPolicyCurrentChange(currentPage) {
      // 当前页码改变
      this.fanyouPolicyCurrentPage = currentPage;
      this.getFanyouSocialPolicyList(1);
    },
    linkToFanyou() {
      // 关联大库政策
      if (this.basicInfoForm.region == [] || this.basicInfoForm.region == "") {
        this.$message({
          message: "请选择所属地区！",
          type: "warning",
        });
        return;
      }
      this.getFanyouSocialPolicyList(1);
    },
    async getFanyouSocialPolicyList(val) {
      if(val==0){
        this.currentPage=1;
        this.pageshow=false;
        this.$nextTick(()=>{
        this.pageshow=true;
        })
        this.fanyouPolicyCurrentPage=1;
      }
      let params = {
        Districts: this.basicInfoForm.region,
        PolicyName: this.fanyouStorageFilterForm.policyName,
        PageCount: this.fanyouPolicyCurrentPage,
        TakeCount: 10,
      };
      this.fanyouStoragePolicyTableLoading = true;
      try {
        let res = await this.$api.policy.fanyouSocialPolicyList(params);
        if (res.code == 200) {
          this.fanyouStoragePolicyData = res.data;
          this.fanyouPolicyTotalCount = res.count;
          this.fanyouStorageDialog = true;
        } else {
          this.$message.error(res.msg);
        }
        this.fanyouStoragePolicyTableLoading = false;
      } catch (e) {
        console.log(e);
        this.fanyouStoragePolicyTableLoading = false;
      }
    },
    SubmitForm() {
      let validRes = [];
      this.$refs["basicInfoForm"].validate((valid) => {
        validRes.push(valid);
      });
      this.$refs["normalPolicyForm"].validate((valid) => {
        validRes.push(valid);
      });
      this.$refs["makeupPolicyForm"].validate((valid) => {
        validRes.push(valid);
      });
      let res = validRes.every((item) => {
        return item;
      });
      
      //年费收取月份非必填项
      let validateNormalPay = this.normalPolicyForm.normalPolicyData.filter(item => item.payType != 0 && item.payMonth == "" )
      let validateBackPay = this.makeupPolicyForm.makeupPolicyData.filter(item => item.payType != 0 && item.payMonth == "" )
      if(validateNormalPay.length > 0 || validateBackPay.length > 0){
         this.$message({
          message: "按年收费需填写年费收取月份！",
          type: "warning",
        });
        return;
      }
      if (res) {
        this.savePolicy();
      } else {
        console.log("error submit!!");
      }
    },
    uploadfileChange(e) {
      // 上传协议文件
      this.uploadTheFile(e.target.files[0]);
    },
    async uploadTheFile(fileTarget) {
      // 上传协议文件api
      let fd = new FormData();
      fd.append("file", fileTarget);
      let params = fd;
      this.uploadingFile = true;
      try {
        let res = await this.$api.policy.uploadFile(params);
        if (res.code == 200) {
          this.$message({
            message: "上传文件成功！",
            type: "success",
          });
          this.basicInfoForm.uploadFile = fileTarget;
          this.basicInfoForm.uploadfileName = fileTarget.name;
          this.basicInfoForm.attachAddress = res.data;
        } else {
          this.$message.error("上传文件失败！");
        }
        this.uploadingFile = false;
      } catch (e) {
        console.log(e);
        this.uploadingFile = false;
      }
    },
    multiImportFileChange(e, type) {
      // 批量导入政策信息
      this.multiImport(e.target.files[0], type);
    },
    async multiImport(file, type) {
      // 批量导入政策信息api
      let fd = new FormData();
      fd.append("type", type);
      fd.append("file", file);
      this.multiImporting = true;
      try {
        let res = await this.$api.policy.multiImport(fd);
        if (res.code == 200) {
          let uploadData = res.data;
          uploadData.forEach((item) => {
            let detailObj = {};
            for (let key in item) {
              if (key != "policyDetailId") {
                detailObj[key] = item[key];
              }
            }
            if (type == 0) {
              this.normalPolicyForm.normalPolicyData.push(detailObj);
            } else {
              this.makeupPolicyForm.makeupPolicyData.push(detailObj);
            }
          });
          this.$message({
            message: "批量导入成功！",
            type: "success",
          });
        } else {
          this.$message.error("上传文件失败！"+res.msg);
        }
        this.multiImporting = false;
        if (type == 0) {
          this.$refs.multiImportFileNormal.value = "";
        } else {
          this.$refs.multiImportFileMakeup.value = "";
        }
      } catch (e) {
        console.log(e);
        this.multiImporting = false;
        if (type == 0) {
          this.$refs.multiImportFileNormal.value = "";
        } else {
          this.$refs.multiImportFileMakeup.value = "";
        }
      }
    },
    addNormalPolicyRow() {
      // 新增正常政策信息row
      let normalPolicyData = this.normalPolicyForm.normalPolicyData;
      if (normalPolicyData == undefined) {
        normalPolicyData = new Array();
      }
      let obj = new Object();
      obj = {
        policyId: 0,
        type: 0,
        name: "",
        enterpriseMinMoney: 0,
        enterpriseNumberCeiling: "",
        enterpriseNumberFloor: "",
        enterprisePercent: "",
        enterpriseBitNumber: "",
        enterpriseValueRule: "",
        personMinMoney: 0,
        personNumberCeiling: "",
        personNumberFloor: "",
        personPercent: "",
        personBitNumber: "",
        personValueRule: "",
        startMonth: "",
        remark: "",
        isNormal: true,
        repairMonth: 0,
        payType: 0,
        payMonth: "",
      };
      normalPolicyData.push(obj);
      this.normalPolicyForm.normalPolicyData = normalPolicyData;
    },
    delNormalPolicy(obj) {
      // 删除正常政策信息row
      let index = obj.$index;
      this.normalPolicyForm.normalPolicyData.splice(index, 1);
    },
    addMakeupPolicyRow() {
      // 新增补缴政策信息row
      let makeupPolicyData = this.makeupPolicyForm.makeupPolicyData;
      if (makeupPolicyData == undefined) {
        makeupPolicyData = new Array();
      }
      let obj = new Object();
      obj = {
        type: 0,
        name: "",
        isNormal: false,
        isPeriod: 0,
        cycleStartYear: "",
        cycleStartMonth: 0,
        cycleEndYear: "",
        cycleEndMonth: 0,
        enterpriseMinMoney: 0,
        enterpriseNumberCeiling: "",
        enterpriseNumberFloor: "",
        enterprisePercent: "",
        enterpriseBitNumber: "",
        enterpriseValueRule: "",
        personMinMoney: 0,
        personNumberCeiling: "",
        personNumberFloor: "",
        personPercent: "",
        personBitNumber: "",
        personValueRule: "",
        startMonth: "",
        remark: "",
        repairMonth: "",
        payType: 0,
        payMonth: "",
      };
      makeupPolicyData.push(obj);
      this.makeupPolicyForm.makeupPolicyData = makeupPolicyData;
    },
    delMakeupPolicy(obj) {
      // 删除补缴政策信息row
      let index = obj.$index;
      this.makeupPolicyForm.makeupPolicyData.splice(index, 1);
    },
    returnGeneralPolicyList() {
      this.$router.push("/GeneralPolicy");
    },
    normalCategoryChange(val, row, index) {
      // 正常--险种类别变更
      this.$set(this.normalPolicyForm.normalPolicyData[index], "name", "");
    },
    makeupCategoryChange(val, row, index) {
      // 补缴--险种类别变更
      this.$set(this.makeupPolicyForm.makeupPolicyData[index], "name", "");
    },
    async downloadDemo(type) {
      // 下载模板
      let params = {
        type: type, // 0-正常，1-补缴
      };
      try {
        let res = await this.$api.policy.downloadDemo(params);
        if (!res) {
          return false;
        }
        const blob = new Blob([res]);
        const downloadElement = document.createElement("a");
        const href = window.URL.createObjectURL(blob);
        downloadElement.style.display = "none";
        downloadElement.href = href;
        downloadElement.download =
          type == 0 ? "政策设置模板-正常.xlsx" : "政策设置模板-补缴.xlsx"; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(href); //释放掉blob对象
      } catch (e) {
        console.log(e);
      }
    },
    async savePolicy() {
      // 保存政策
      let districts = this.basicInfoForm.region;
      let cities = [];
      for (let i of districts) {
        cities.push(i.slice(0, i.indexOf("市")));
      }
      let isOneCity = cities.every((city) => {
        return city === cities[0];
      });
      if (!isOneCity) {
        this.$message({
          message: "所属地区只能选择相同的城市！",
          type: "warning",
        });
        return;
      }
      let policyNormalDetails = this.normalPolicyForm.normalPolicyData;
      let policyRepairDetails = this.makeupPolicyForm.makeupPolicyData;
      let newpolicyNormalDetails = [];
      let newpolicyRepairDetails = [];
      policyNormalDetails.forEach((item) => {
        let policyNormalDetailObj = {};
        for (let key in item) {
          if (key != "policyDetailId") {
            policyNormalDetailObj[key] = item[key];
          }
        }
        newpolicyNormalDetails.push(policyNormalDetailObj);
      });
      policyRepairDetails.forEach((item) => {
        item.peridStartMonth =
          item.isPeriod == 0
            ? "不限"
            : item.cycleStartYear + item.cycleStartMonth + "月";
        item.peridEndMonth =
          item.isPeriod == 0
            ? "不限"
            : item.cycleEndYear + item.cycleEndMonth + "月";
        let policyRepairDetailObj = {};
        for (let key in item) {
          if (key != "policyDetailId") {
            policyRepairDetailObj[key] = item[key];
          }
        }
        newpolicyRepairDetails.push(policyRepairDetailObj);
      });
      let params = {
        policy: {
          policyId: "",
          policyNumber: "",
          enterpriseId: "",
          enterpriseName: "",
          policyNames: this.basicInfoForm.policyName.split(","),
          districts: this.basicInfoForm.region,
          insuranceType: this.basicInfoForm.type,
          // status: "",
          startMonth: this.basicInfoForm.effectiveTime,
          updateTime: new Date(),
          source: 0, // 0通用1单位
          // description: "",
          attachAddress: this.basicInfoForm.attachAddress, // 上传协议文件API返回的地址
        },
        policyNormalDetails: newpolicyNormalDetails,
        policyRepairDetails: newpolicyRepairDetails,
      };
      this.savingPolicy = true;
      try {
        let res = await this.$api.policy.addPolicy(params);
        if (res.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.savingPolicy = false;
          this.returnGeneralPolicyList();
        } else {
          this.savingPolicy = false;
          this.$message.error(res.msg);
        }
      } catch (e) {
        console.log(e);
        this.$message.error("保存失败！");
        this.savingPolicy = false;
      }
    },
    async getDistrict() {
      try {
        let res = await this.$api.policy.getDistrict({ enterpriseName: "" });
        if (res.code == 200) {
          this.basicInfoForm.regionList = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    districtChange(flag) {
      if (!flag) {
        if (this.selectedRegion == "" || this.selectedRegion == []) {
          this.selectedRegion = this.basicInfoForm.region;
          this.basicInfoForm.type = "";
          this.basicInfoForm.typeList = [];
          this.basicInfoForm.policyName = "";
          this.getInsurance(this.basicInfoForm.region);
        } else {
          if (this.selectedRegion != this.basicInfoForm.region) {
            this.selectedRegion = this.basicInfoForm.region;
            this.basicInfoForm.type = "";
            this.basicInfoForm.typeList = [];
            this.basicInfoForm.policyName = "";
            this.getInsurance(this.basicInfoForm.region);
          }
        }
      }
    },
    removeDistrictTag() {
      this.basicInfoForm.type = "";
      this.basicInfoForm.typeList = [];
      this.basicInfoForm.policyName = "";
      this.getInsurance(this.basicInfoForm.region);
    },
    async getInsurance(districtName) {
      try {
        let res = await this.$api.policy.getInsurance(districtName);
        if (res.code == 200) {
          this.basicInfoForm.typeList = res.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    typeChange(type) {
      this.getPolicyName(type);
    },
    async getPolicyName(type) {
      let reqList = [];
      for (let i = 0; i < this.basicInfoForm.region.length; i++) {
        let req = this.$api.policy.getPolicyName({
          source: 0,
          district: this.basicInfoForm.region[i],
          insuranceType: type,
        });
        reqList.push(req);
      }
      try {
        let res = await Promise.all(reqList);
        let policyNameList = [];
        if (res) {
          res.forEach((item) => {
            policyNameList.push(item.data);
          });
        } else {
          policyNameList = [];
        }
        this.basicInfoForm.policyName = policyNameList.join(",");
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    // 获取地区API -> 获取地区对应参保类型 -> 获取政策名称
    this.getDistrict();
  },
};
</script>


<style src="@/assets/public.css"></style>
<style scoped>
#addGeneralPolicy {
  padding: 20px;
}
.basic-info {
  width: 100%;
  font-size: 14px;
  background: #fafafa;
  padding: 0 20px;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid #ededed;
}
.el-collapse {
  border-top: 0;
}
.basic-info-form {
  padding: 20px 20px 0;
}
.basic-info-form .el-select {
  width: 100%;
}
.basic-info-form .el-date-editor {
  width: 100%;
}
.policy-info {
  margin: 20px 0 0;
}
.control-panel {
  width: 100%;
  height: 48px;
  background: #fafafa;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #ededed;
  box-sizing: border-box;
}
.uploadfileName {
  margin-left: 8px;
}
</style>
