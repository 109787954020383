<template>
    <div id="BillList">
        <div class="page-header">
            <p class="page-path">
                账单管理<span class="slashline">/</span>应付账单
            </p>
            <p class="page-title">应付账单列表</p>
        </div>
        <div class="operation-pannel">
            <el-form :model="filters"
                     class="filters-form"
                     label-width="110px"
                     ref="filtersform">
                <el-row :gutter="16">
                    <el-col :span="6">
                        <el-form-item label="单位名称：" prop="EnterpriseName">
                            <el-input v-model="filters.EnterpriseName" placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="托管网点：" prop="Branch">
                            <el-input v-model="filters.Branch"
                                      placeholder="请输入"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="出账月份：" prop="Month">
                            <el-date-picker :editable="true"
                                            v-model="filters.Month"
                                            value-format="yyyy-MM"
                                            type="month"
                                            placeholder="请选择">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="primary" @click="searchData">查询</el-button>
                        <el-button type="default"
                                   @click="
            resetFilter();
            searchData();
            ">重置</el-button>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="账单类型：" prop="BillType">
                            <el-select v-model="filters.BillType"
                                       placeholder="请选择"
                                       clearable>
                                <el-option label="开户账单" :value="3"></el-option>
                                <el-option label="服务费账单" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="table-container">
            <el-table max-height="500"
                      :data="tableData"
                      ref="tableData"
                      border
                      style="width: 100%; margin-bottom: 30px"
                      v-loading="tableLoading">
                <el-table-column type="index"
                                 label="序号"
                                 width="50">
                </el-table-column>
                <el-table-column prop="customer" label="所属客户"></el-table-column>
                <el-table-column prop="enterpriseId" label="单位编号"></el-table-column>
                <el-table-column prop="enterpriseName" label="单位名称"></el-table-column>
                <el-table-column prop="branch" label="托管网点"></el-table-column>
                <el-table-column prop="billType" label="账单类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.billType == 0">社保</span>
                        <span v-else-if="scope.row.billType == 1">公积金</span>
                        <span v-else-if="scope.row.billType == 2">服务费台账</span>
                        <span v-else-if="scope.row.billType == 3">开户台账</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="city" label="参缴城市"></el-table-column> -->
                <el-table-column prop="month" label="出账月份">
                    <template slot-scope="scope">
                        <div>
                            {{ commonjs.dateFtt("yyyy-MM", new Date(scope.row.month)) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="creationTime" label="账单生成时间"></el-table-column>
                <el-table-column prop="totalCost" label="账单金额"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="default" @click="getDetail(scope.row)">查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :total="totalCount"
                           :page-size="pageSize"
                           :current-page="currentPage"
                           layout="prev, pager, next, sizes, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    // import Cookie from "js.cookie";
    export default {
        name: "BillList",
        components: {},
        data: function () {
            return {
                Id: "",
                tableData: [],
                filters: {
                    EnterpriseName: "",
                    BillType: "",
                    Branch: "",
                    Status: "",
                    Month: ""
                },
                pageSize: 10,
                currentPage: 1,
                totalCount: 0,
                tableLoading: false,
                addShow: false,
                auditShow: false,
                failReason: "",
                auditFailVisible: false,
                auditoptions: [{
                    value: '0',
                    label: '待提交'
                }, {
                    value: '1',
                    label: '待审核'
                }, {
                    value: '2',
                    label: '审核拒绝'
                }, {
                    value: '3',
                    label: '待推送'
                }, {
                    value: '4',
                    label: '已推送'
                }
                ],
                companyArray: [],
                ImportDataForm: {
                    EnterpriseName: "",
                    Month: "",
                    BillType: "",
                },
                fileList: [],
                ImportDataDialog: false,
                importBtnSubmitLoading: false,
            };
        },
        methods: {
            //重置查询条件
            resetFilter() {
                this.filters = {};
            },
            handleSizeChange(pageSize) {
                // 每页条数改变
                this.pageSize = pageSize;
                this.getList();
            },
            handleCurrentChange(currentPage) {
                // 当前页码改变
                this.currentPage = currentPage;
                this.getList();
            },
            //台账详情
            billDetail() {
                //根据类型查找对应的明细，加载不同界面
                let selection = this.$refs.tableData.selection;
                if (selection == undefined || selection.length <= 0) {
                    this.$message({
                        message: "请选择需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                if (selection == undefined || selection.length > 1) {
                    this.$message({
                        message: "请选择1条需要处理的数据",
                        type: "warning",
                        showClose: true,
                    });
                    return;
                }
                console.log(selection[0]);
                if (selection[0].billType == "0") {

                    this.$router.push({
                        name: "SocialBillDetail",
                        params: {
                            bid: selection[0].id,
                        },
                    });
                }
                else if (selection[0].billType == "1") {
                    this.$router.push({
                        name: "AccBillDetail",
                        params: {
                            bid: selection[0].id,
                        },
                    });
                }
                else if (selection[0].billType == "2") {
                    this.$router.push({
                        name: "ServiceCostBillDetail",
                        params: {
                            bid: selection[0].id,
                        },
                    });
                }
                else if (selection[0].billType == "3") {
                    this.$router.push({
                        name: "CostOpenBillDetail",
                        params: {
                            bid: selection[0].id,
                        },
                    });
                }
            },
            getDetail(row) {
                if (row.billType == "0") {
                    this.$router.push({
                        name: "SocialBillDetail",
                        params: {
                            bid: row.id,
                        },
                    });
                }
                else if (row.billType == "1") {
                    this.$router.push({
                        name: "AccBillDetail",
                        params: {
                            bid: row.id,
                        },
                    });
                }
                else if (row.billType == "2") {
                    this.$router.push({
                        name: "ServiceCostBillDetail",
                        params: {
                            bid: row.id,
                        },
                    });
                }
                else if (row.billType == "3") {
                    this.$router.push({
                        name: "CostOpenBillDetail",
                        params: {
                            bid: row.id,
                        },
                    });
                }
            },
            //查询
            searchData() {
                this.pageSize = 10;
                this.currentPage = 1;
                this.totalCount = 0;
                this.getList();
            },
            async getList() {
                let params = {
                    pageCount: this.currentPage,
                    takeCount: this.pageSize,
                    //bid: this.Id,
                    EnterpriseName: this.filters.EnterpriseName,
                    Branch: this.filters.Branch,
                    Status: this.filters.Status,
                    Month: this.filters.Month,
                    BillType: this.filters.BillType,
                    type: 1
                };
                this.tableLoading = true;
                try {
                    console.log(params);
                    let res = await this.$api.SaleBill.SaleBillList(params);
                    if (res) {
                        this.totalCount = res.totalCount;
                        this.tableData = res.item;
                    } else {
                        this.$message.error("查询出错");
                    }
                    this.tableLoading = false;
                } catch (e) {
                    console.log(e);
                    this.tableLoading = false;
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            onChangeFile(file, fileList) {
                let f = file.name.split('.').pop();
                if (f != "xls" && f != "xlsx") {
                    fileList = []
                    this.fileList = []
                    this.$message({
                        message: '请上传 .xls|.xlsx 格式的文件',
                        type: 'error'
                    })
                }
                else {
                    this.fileList = fileList.length > 0 ? [file.raw] : []
                }
            },
            async IsOperable(pid, userid) {
                try {
                    let res = await this.$api.authority.IsOperable(pid, userid);
                    if (pid == "OP100345") {
                        this.addShow = res;
                    }
                    if (pid == "OP100346") {
                        this.auditShow = res;
                    }
                } catch (e) {
                    console.log(e);
                    this.$message.error("获取按钮权限失败！");
                }
            },
            getBtnAuth() {

                // 临时处理Dev/QA获取不到Cookie的情况 _2021.08.11
                let userId = this.$cookies.get("userId");
                if (userId == null || userId == "") userId = "e92983fd6e4c4f598b1e7dfb9d6365d1";

                this.IsOperable("OP100345", userId);
                this.IsOperable("OP100346", userId);
            },
        },
        mounted() {
            this.getList();
            //this.getBtnAuth();
        },
    };
</script>

<style src="@/assets/public.css"></style>
<style scoped>
    #BillList {
        padding: 20px;
    }

    .op-row {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        font-size: 14px;
    }

    .row-input {
        width: 240px;
        margin: 0 20px 0 10px;
    }

    .table-container {
        background: #fff;
        padding: 0 20px 20px;
    }
</style>
