<template>
  <div id="CostTemplate">
    <div class="page-header">
      <p class="page-path">
        账单管理<span class="slashline">/</span>台账模板管理
      </p>
      <p class="page-title">费用模板管理</p>
    </div>
    <div class="operation-pannel">
      <div class="op-row">
        模板名称：<el-input
          class="row-input"
          v-model="TemplateName"
          placeholder="请输入"
        ></el-input>
        关联网点：<el-input
          class="row-input"
          v-model="BranchesName"
          placeholder="请输入"
        ></el-input>
        <el-col :span="1"><div class="grid-content"></div></el-col>
        <el-button type="primary" @click="searchData">查询</el-button>
        <el-button
          @click="
            resetFilter();
            searchData();
          "
          >重置</el-button
        >
      </div>
      <div class="op-row">
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="addCostTemplate"
          v-show="addShow"
          >新建模板</el-button
        >
        <el-button @click="editCostTemplate" v-show="editShow"
          >编辑模板</el-button
        >
        <el-button @click="CostTemplateDetail" v-show="true"
          >查看详情</el-button
        >
      </div>
    </div>
    <div class="table-container">
      <el-table
        max-height="500"
        :data="tableData"
        ref="tableData"
        border
        style="width: 100%; margin-bottom: 30px"
        v-loading="tableLoading"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          :index="indexMethod"
          width="50"
        >
        </el-table-column>
        <el-table-column prop="name" label="模板名称"></el-table-column>
        <el-table-column prop="creationTime" label="创建时间"></el-table-column>
        <el-table-column label="修改时间">
          <template slot-scope="scope">
            <div v-if="new Date(scope.row.editTime).getFullYear() > 2001">
              {{ scope.row.editTime }}
            </div>
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column prop="branchesName" label="关联网点">
        </el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template slot-scope="scope">
            <OptLog :menuId="menuId" :relationId="scope.row.id" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="totalCount"
        :page-size="pageSize"
        :current-page="currentPage"
        layout="prev, pager, next, sizes, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import Cookie from "js.cookie";
export default {
  name: "CostTemplate",
  components: {},
  data: function () {
    return {
      menuId: "",
      TemplateId: "",
      TemplateName: "",
      BranchesName: "",
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      totalCount: 0,
      tableLoading: false,
      addShow: false,
      editShow: false,
    };
  },
  async created() {
    let menuId = await this.$api.publicapi.getMenuId("台账管理-费用模板管理");
    this.menuId = menuId;
  },

  methods: {
    indexMethod(index) {
      //实现表格序号自增
      let curpage = this.currentPage;
      let limitpage = this.pageSize;
      return index + 1 + (curpage - 1) * limitpage;
    },
    resetFilter() {
      this.TemplateName = this.BranchesName = "";
    },
    checkDetails(id) {
      this.$router.push({
        name: "CostTemplateDetail",
        params: {
          bid: id,
        },
      });
    },
    handleSizeChange(pageSize) {
      // 每页条数改变
      this.pageSize = pageSize;
      this.getList();
    },
    handleCurrentChange(currentPage) {
      // 当前页码改变
      this.currentPage = currentPage;
      this.getList();
    },
    addCostTemplate() {
      this.$router.push("/AddCostTemplate");
    },
    //模板详情
    CostTemplateDetail() {
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "请选择1条需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.$router.push({
        name: "CostTemplateDetail",
        params: {
          bid: selection[0].id,
        },
      });
    },
    //编辑模板
    editCostTemplate() {
      let selection = this.$refs.tableData.selection;
      if (selection == undefined || selection.length <= 0) {
        this.$message({
          message: "请选择需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (selection == undefined || selection.length > 1) {
        this.$message({
          message: "请选择1条需要处理的数据",
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.$router.push({
        name: "EditCostTemplate",
        params: {
          bid: selection[0].id,
        },
      });
    },
    /* async BranchEffectiveChange(val, scope) {
              let params = {
                bid: scope.row.id,
                flag: val,
              };
              this.tableLoading = true;
              try {
                let res = await this.$api.branch.BranchEffective(params);
                if (!res) {
                  this.$message.error("设置失败！");
                }
                this.tableLoading = false;
              } catch (e) {
                console.log(e);
                this.tableLoading = false;
              }
            }, */
    searchData() {
      this.pageSize = 10;
      this.currentPage = 1;
      this.totalCount = 0;
      this.getList();
    },
    async getList() {
      let params = {
        pageCount: this.currentPage,
        takeCount: this.pageSize,
        //bid: this.Id,
        Name: this.TemplateName,
        BranchesName: this.BranchesName,
      };
      this.tableLoading = true;
      try {
        let res = await this.$api.BillManage.CostTemplateList(params);
        if (res) {
          this.totalCount = res.totalCount;
          this.tableData = res.item;
        } else {
          this.$message.error("查询出错");
        }
        this.tableLoading = false;
      } catch (e) {
        console.log(e);
        this.tableLoading = false;
      }
    },
    async IsOperable(pid, userid) {
      try {
        let res = await this.$api.authority.IsOperable(pid, userid);
        if (pid == "OP100360") {
          this.addShow = res;
        }
        if (pid == "OP100361") {
          this.editShow = res;
        }
      } catch (e) {
        console.log(e);
        this.$message.error("获取按钮权限失败！");
      }
    },
    getBtnAuth() {
      // 临时处理Dev/QA获取不到Cookie的情况 _2021.08.11
      let userId = this.$cookies.get("userId");
      if (userId == null || userId == "")
        userId = "e92983fd6e4c4f598b1e7dfb9d6365d1";

      this.IsOperable("OP100360", userId);
      this.IsOperable("OP100361", userId);
    },
  },
  mounted() {
    this.getList();
    this.getBtnAuth();
  },
};
</script>

<style src="@/assets/public.css"></style>
<style scoped>
#CostTemplate {
  padding: 20px;
}

.op-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.row-input {
  width: 240px;
  margin: 0 20px 0 10px;
}

.table-container {
  background: #fff;
  padding: 0 20px 20px;
}
</style>
