import Vue from 'vue'
import VueRouter from 'vue-router'
import ServiceBranch from '../views/branch/ServiceBranch.vue'
import AddBranch from '../views/branch/AddBranch.vue'
import CopyAddBranch from '../views/branch/CopyAddBranch.vue'
import BranchDetail from '../views/branch/BranchDetail.vue'
import EditBranch from '../views/branch/EditBranch.vue'
import OpenAccount from '../views/OpenAccount.vue'
import GeneralPolicyList from '../views/generalPolicy/list.vue'
import GeneralPolicyAdd from '../views/generalPolicy/add.vue'
import GeneralPolicyDetail from '../views/generalPolicy/detail.vue'
import GeneralPolicyEdit from '../views/generalPolicy/edit.vue'
import UnitPolicyList from '../views/unitPolicy/list.vue'
import UnitPolicyAdd from '../views/unitPolicy/add.vue'
import UnitPolicyDetail from '../views/unitPolicy/detail.vue'
import UnitPolicyEdit from '../views/unitPolicy/edit.vue'
import BusinessAcceptance from '../views/BusinessAcceptance.vue'
import OrderFeedback from '../views/OrderFeedback.vue'
import InsuredEmployees from '../views/InsuredEmployees.vue'
import HostingApplyList from '../views/Hosting/ApplyList.vue'
import AddHosting from '../views/Hosting/AddHosting.vue'
import HostingAccountList from '../views/Hosting/AccountList.vue'
import HostingAccountDetail from '../views/Hosting/AccountDetail.vue'
import OrderInfo from '../views/OrderInfo.vue'
import CostTemplateManage from '../views/BillManage/CostTemplateManage.vue'
import AddCostTemplate from '../views/BillManage/AddCostTemplate.vue'
import CostTemplateDetail from '../views/BillManage/CostTemplateDetail.vue'
import EditCostTemplate from '../views/BillManage/EditCostTemplate.vue'
import SaleBillList from '../views/BillManage/saleBill/saleBillList.vue'
import CostBillList from '../views/BillManage/costBill/costBillList.vue'
import SocialBillDetail from '../views/BillManage/saleBill/socialBillDetail.vue'
import AccBillDetail from '../views/BillManage/saleBill/accBillDetail.vue'
import ServiceBillDetail from '../views/BillManage/saleBill/serviceBillDetail.vue'
import OpenBillDetail from '../views/BillManage/saleBill/openBillDetail.vue'
import ServiceCostBillDetail from '../views/BillManage/costBill/serviceBillDetail.vue'
import CostOpenBillDetail from '../views/BillManage/costBill/openBillDetail.vue'
import ApplyFieldList from '../views/applyField/applyFieldList.vue'
import ApplyFieldOperation from '../views/applyField/applyFieldOperation.vue'
import ApplyFieldDetail from '../views/applyField/applyFieldDetail.vue'
import OperateDeadlineList from '../views/operateDeadline/operateDeadlineList.vue'
import PersonMove from '../views/statistics/PersonMove.vue'
import PersonnelUnderProtection from '../views/PersonnelUnderProtection/index.vue'
Vue.use(VueRouter)

const routes = [
    {
        path: '/PersonnelUnderProtection',
        name: 'PersonnelUnderProtection',
        component: PersonnelUnderProtection
    },
    {
        path: '/OperateDeadlineList',
        name: 'OperateDeadlineList',
        component: OperateDeadlineList
    },
    {
        path: '/ApplyFieldDetail',
        name: 'ApplyFieldDetail',
        component: ApplyFieldDetail
    },
    {
        path: '/ApplyFieldList',
        name: 'ApplyFieldList',
        component: ApplyFieldList
    },
    {
        path: '/ApplyFieldOperation',
        name: 'ApplyFieldOperation',
        component: ApplyFieldOperation
    },
    {
        path: '/SaleBillList',
        name: 'SaleBillList',
        component: SaleBillList,
        meta: {
            keepAlive: true // 需要缓存
        }
    },
    {
        path: '/CostBillList',
        name: 'CostBillList',
        component: CostBillList
    }, 
    {
        path: '/CostOpenBillDetail',
        name: 'CostOpenBillDetail',
        component: CostOpenBillDetail
    },
    {
        path: '/ServiceCostBillDetail',
        name: 'ServiceCostBillDetail',
        component: ServiceCostBillDetail
    },  {
        path: '/ServiceBillDetail',
        name: 'ServiceBillDetail',
        component: ServiceBillDetail
    }, {
        path: '/AccBillDetail',
        name: 'AccBillDetail',
        component: AccBillDetail
    },{
        path: '/OpenBillDetail',
        name: 'OpenBillDetail',
        component: OpenBillDetail
    },  {
        path: '/SocialBillDetail',
        name: 'SocialBillDetail',
        component: SocialBillDetail
    }, {
        path: '/CostTemplateManage',
        name: 'CostTemplateManage',
        component: CostTemplateManage
    }, {
        path: '/AddCostTemplate',
        name: 'AddCostTemplate',
        component: AddCostTemplate
    }, {
        path: '/CostTemplateDetail',
        name: 'CostTemplateDetail',
        component: CostTemplateDetail
    }, {
        path: '/EditCostTemplate',
        name: 'EditCostTemplate',
        component: EditCostTemplate
    }, {
        path: '/ServiceBranch',
        name: 'ServiceBranch',
        component: ServiceBranch
    }, {
        path: '/CopyAddBranch',
        name: 'CopyAddBranch',
        component: CopyAddBranch
    },{
        path: '/AddBranch',
        name: 'AddBranch',
        component: AddBranch
    }, {
        path: '/BranchDetail',
        name: 'BranchDetail',
        component: BranchDetail
    }, {
        path: '/EditBranch',
        name: 'EditBranch',
        component: EditBranch
    }, {
        path: '/OpenAccount',
        name: 'OpenAccount',
        component: OpenAccount
    }, {
        path: '/GeneralPolicy',
        name: 'GeneralPolicyList',
        component: GeneralPolicyList
    }, {
        path: '/GeneralPolicyAdd',
        name: 'GeneralPolicyAdd',
        component: GeneralPolicyAdd
    }, {
        path: '/GeneralPolicyDetail',
        name: 'GeneralPolicyDetail',
        component: GeneralPolicyDetail
    }, {
        path: '/GeneralPolicyEdit',
        name: 'GeneralPolicyEdit',
        component: GeneralPolicyEdit
    }, {
        path: '/UnitPolicy',
        name: 'UnitPolicyList',
        component: UnitPolicyList
    }, {
        path: '/UnitPolicyAdd',
        name: 'UnitPolicyAdd',
        component: UnitPolicyAdd
    }, {
        path: '/UnitPolicyDetail',
        name: 'UnitPolicyDetail',
        component: UnitPolicyDetail
    }, {
        path: '/UnitPolicyEdit',
        name: 'UnitPolicyEdit',
        component: UnitPolicyEdit
    }, {
        path: '/BusinessAcceptance',
        name: 'BusinessAcceptance',
        component: BusinessAcceptance
    }, {
        path: '/OrderFeedback',
        name: 'OrderFeedback',
        component: OrderFeedback
    }, {
        path: '/InsuredEmployees',
        name: 'InsuredEmployees',
        component: InsuredEmployees,
        meta: {
            keepAlive: true // 需要缓存
        }
    }, {
        path: '/HostingApplyList',
        name: 'HostingApplyList',
        component: HostingApplyList
    }, {
        path: '/AddHosting',
        name: 'AddHosting',
        component: AddHosting
    }, {
        path: '/HostingAccountList',
        name: 'HostingAccountList',
        component: HostingAccountList
    }, {
        path: '/HostingAccountDetail',
        name: 'HostingAccountDetail',
        component: HostingAccountDetail
    },
    {
        path: '/OrderInfo',
        name: 'OrderInfo',
        component: OrderInfo
    },
    {
        path: '/PersonMove',
        name: 'PersonMove',
        component: PersonMove
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
