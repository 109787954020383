<template>
  <div id="addGeneralPolicy">
    <div class="page-header">
      <p class="page-path">
        账单管理<span class="slashline">/</span>台账模板管理<span
          class="slashline"
          >/</span
        >新增模板
      </p>
      <p class="page-title">新增费用模板</p>
    </div>
    <div class="operation-pannel">
    <el-form
      :model="basicInfoForm"
      label-position="top"
      ref="basicInfoForm"
      class="basic-info-form" :rules = "basicInfoFormRules">
      <el-collapse v-model="activeNames" >
        <el-collapse-item name="1">
          <template slot="title">
            <div class="basic-info">基本模板信息</div>
          </template>
            <el-row :gutter="5">
              <label style="height:5px">.</label>
            </el-row>
            <el-row :gutter="30" >
              <el-col :span="10" >
                <el-form-item prop="type">
                   <label style="width:154px;margin-left:84px"><span style="text-align:right">模板类型：</span></label>
                  <el-select
                    v-model="basicInfoForm.type"
                    placeholder="请选择"
                    @change="typeChange"
                    style="width:50%"
                  >
                      <el-option label="开户通用模板" :value="0"></el-option>
                      <el-option label="托管通用模板" :value="1"></el-option>
                      <el-option label="自定义网点托管模板" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item  prop="name">
                  <label style="width:154px;margin-left:84px"><span style="text-align:right">模板名称：</span></label>
                  <el-input
                    v-model="basicInfoForm.name"
                    placeholder=""
                    :disabled="true"
                    style="width:50%"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
             <el-row :gutter="30">
              <el-col :span="16">
                <el-form-item prop="remark">
                  <label style="width:154px;margin-left:105px"><span style="text-align:right">备&nbsp;注：</span></label>
                  <el-input
                    type = "textarea"
                    v-model="basicInfoForm.remark"
                    placeholder="请输入"
                    :disabled="false"
                    :rows="4"
                    style="width:70%;margin-left:2px"
                    maxlength="200"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
        </el-collapse-item>
        <el-collapse-item name = "2">
          <template slot="title">
            <div class="basic-info">版本详情</div>
          </template>
          <el-row :gutter="5">
            <label style="height:5px">.</label>
          </el-row>
          <el-row :gutter = "30">
             <el-col :span="10">
                <el-form-item prop="effectMonth">
                   <label style="width:154px;margin-left:84px"><span style="text-align:right">生效月份：</span></label>
                  <el-date-picker
                  :editable="true"
                  :disabled="false"
                  style="width:50%;"
                  @change="selectTime"
                  v-model="basicInfoForm.effectMonth"
                  type="month"
                  value-format="yyyy-MM"
                  placeholder="请选择"
                >
                </el-date-picker>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="8">
                <el-form-item prop="version">
                <label >版本</label>
                <el-select clearable v-model="basicInfoForm.version" filterable placeholder="请选择">
                  <el-option v-for="(item,index) in versionList"  :key="index" :label="item.version" :value="item" @change="typeChange"></el-option>
                </el-select>
                </el-form-item>
              </el-col> -->
          </el-row>
          <el-row :gutter="30">
              <el-col :span="16">
                <el-form-item v-show="checkType" prop="BranchesName">
                  <label style="width:154px;margin-left:84px"><span style="text-align:right">关联网点：</span></label>
                  <el-button @click="ChooseBranches" style="background-color:rgba(254,64,102,.25);text-align:center">关联网点</el-button>
                  <el-input
                    type = "textarea"
                    v-model="basicInfoForm.branchesName"
                    placeholder="请点击选择关联网点"
                    :disabled="true"
                    style="width:63%;margin-left:2px"
                    :rows="4"
                    maxlength="512"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
        </el-collapse-item>
        <el-collapse-item name="3">
          <template slot="title">
            <div class="basic-info">销售价设置</div>
          </template>
            <el-row :gutter="5">
              <label style="height:5px">.</label>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item prop="socialSaleCost">
                  <label style="margin-left:57px;width:20%;text-align:right">
                  <template>
                    <span v-if=" basicInfoForm.type == null">社保开户费用：</span>
                    <span v-else-if="basicInfoForm.type == 0">社保开户费用：</span>
                    <span v-else>社保托管费用：</span>
                  </template>
                  </label>
                  <el-input 
                    :span="3"
                    v-model="basicInfoForm.socialSaleCost"
                    placeholder="请输入"
                    :disabled="false"
                    style="width:50%"
                  ><template slot="append"><template>
                    <span v-if=" basicInfoForm.type == null">元/次</span>
                    <span v-else-if="basicInfoForm.type == 0">元/次</span>
                    <span v-else>元/人次</span>
                  </template></template></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                
                <el-form-item prop="accSaleCost" >
                  <label style="width:20%;"><template>
                    <span v-if=" basicInfoForm.type == null">公积金开户费用：</span>
                    <span v-else-if="basicInfoForm.type == 0">公积金开户费用：</span>
                    <span v-else>公积金托管费用：</span>
                  </template></label>
                  <el-input 
                    v-model="basicInfoForm.accSaleCost"
                    placeholder="请输入"
                    :disabled="false"
                    style="width:50%"
                  ><template slot="append"><template>
                    <span v-if=" basicInfoForm.type == null">元/次</span>
                    <span v-else-if="basicInfoForm.type == 0">元/次</span>
                    <span v-else>元/人次</span>
                  </template></template></el-input>
                </el-form-item>
              </el-col>
            </el-row>
             <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item prop="socialAndAccSaleCost" >
                   <label style="width:20%;"><template>
                    <span v-if=" basicInfoForm.type == null">社保和公积金开户费用：</span>
                    <span v-else-if="basicInfoForm.type == 0">社保和公积金开户费用：</span>
                    <span v-else>社保和公积金托管费用：</span>
                  </template></label>
                  <el-input
                    v-model="basicInfoForm.socialAndAccSaleCost"
                    placeholder="请输入"
                    :disabled="false"
                    style="width:50%"
                  ><template slot="append"><template>
                    <span v-if=" basicInfoForm.type == null">元/次</span>
                    <span v-else-if="basicInfoForm.type == 0">元/次</span>
                    <span v-else>元/人次</span>
                  </template></template></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          
        </el-collapse-item>
         <el-collapse-item name="4">
          <template slot="title">
            <div class="basic-info">成本价设置</div>
          </template>
            <el-row :gutter="5">
              <label style="height:5px">.</label>
            </el-row>
            <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item prop="socialCost" >
                  <label style="margin-left:57px;width:20%;text-align:right">
                  <template>
                    <span v-if=" basicInfoForm.type == null">社保开户成本：</span>
                    <span v-else-if="basicInfoForm.type == 0">社保开户成本：</span>
                    <span v-else>社保托管成本：</span>
                  </template>
                  </label>
                  <el-input 
                    :span="3"
                    v-model="basicInfoForm.socialCost"
                    placeholder="请输入"
                    :disabled="false"
                    style="width:50%"
                  ><template slot="append"><template>
                    <span v-if=" basicInfoForm.type == null">元/次</span>
                    <span v-else-if="basicInfoForm.type == 0">元/次</span>
                    <span v-else>元/人次</span>
                  </template></template></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item prop="accCost" >
                  <label style="width:20%;"><template>
                    <span v-if=" basicInfoForm.type == null">公积金开户成本：</span>
                    <span v-else-if="basicInfoForm.type == 0">公积金开户成本：</span>
                    <span v-else>公积金托管成本：</span>
                  </template></label>
                  <el-input
                    v-model="basicInfoForm.accCost"
                    placeholder="请输入"
                    :disabled="false"
                    style="width:50%"
                  ><template slot="append"><template>
                    <span v-if=" basicInfoForm.type == null">元/次</span>
                    <span v-else-if="basicInfoForm.type == 0">元/次</span>
                    <span v-else>元/人次</span>
                  </template></template></el-input>
                </el-form-item>
              </el-col>
            </el-row>
             <el-row :gutter="30">
              <el-col :span="10">
                <el-form-item prop="socialAndAccCost" >
                   <label style="width:20%;"><template>
                    <span v-if=" basicInfoForm.type == null">社保和公积金开户成本：</span>
                    <span v-else-if="basicInfoForm.type == 0">社保和公积金开户成本：</span>
                    <span v-else>社保和公积金托管成本：</span>
                  </template></label>
                  <el-input 
                    v-model="basicInfoForm.socialAndAccCost"
                    placeholder="请输入"
                    :disabled="false"
                    style="width:50%"
                  ><template slot="append"><template>
                    <span v-if=" basicInfoForm.type == null">元/次</span>
                    <span v-else-if="basicInfoForm.type == 0">元/次</span>
                    <span v-else>元/人次</span>
                  </template></template></el-input>
                </el-form-item>
              </el-col>
            </el-row>
        </el-collapse-item>
      </el-collapse>
    </el-form>
      <div class="bottom-btn-group">
        <el-button @click="returnGeneralList">取消</el-button>
        <el-button type="primary" @click="SubmitForm()" :loading="savingTemplate"
          >保存</el-button
        >
      </div>
    </div>
    <el-dialog
      title="关联网点"
      :visible.sync="BranchesDialog"
      @closed="BranchesData = []"
    >
      <el-form
        :inline="true"
        ref="BranchesFilterForm"
        :model="BranchesFilterForm"
      >
        <el-form-item label="网点名称：" prop="BranchName">
          <el-input
            v-model="BranchName"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchBranches"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        ref="BranchesTable"
        max-height="380"
        :data="BranchesData"
        border
        v-loading="BranchesTableLoading"
        :row-key="getRowKeys"
      >
        <el-table-column
        type="index"
        label="序号"
        width="50">
        </el-table-column>
        <el-table-column
          property="name"
          label="网点名称"
        ></el-table-column>
        <el-table-column type="selection" width="48" :selectable="initSeleTable" :reserve-selection="true" label="操作">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="BranchesCurrentChange"
        :total="BranchesTotalCount"
        layout="total, prev, pager, next"
        style="margin-top: 16px"
      >
      </el-pagination>
      <div slot="footer" class="dialog-footer">
        <el-button @click="BranchesDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="getBranchesSelected"
          :loading="linkingTotable"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AddNetwork",
  components: {},
  data: function () {
    return {
      activeNames: ["1","2","3","4"],
      selectedRegion: [],
      checkType:false,
      versionList:[],
      UnSelectedList:[],
      basicInfoForm: {
        name: "",
        type: "",
        branches: "",
        branchesName: "",
        socialSaleCost: "",
        accSaleCost: "",
        socialAndAccSaleCost: "",
        socialCost: "",
        accCost: "",
        socialAndAccCost: "",
        remark: "",
        effectMonth:""
      },
      pickerOptions1:{
        disabledDate:time=> {
          return time.getTime() > new Date();
        }
      },
      basicInfoFormRules: {
        type: [
          { required: true, message: "请选择模板类型", trigger: "change" },
        ],
        effectMonth: [
          { required: true, message: "请选择生效月份", trigger: "change" },
        ],
        socialSaleCost: [
          { required: true, message: "社保费用必填", trigger: "change" },
          { pattern: /^\d+(\.\d+)?$/, message: "必须为大于等于0的数字", trigger: "blur" },
        ],
        accSaleCost: [
          { required: true, message: "公积金费用必填", trigger: "change" },
          { pattern: /^\d+(\.\d+)?$/, message: "必须为大于等于0的数字", trigger: "blur" },
        ],
        socialAndAccSaleCost: [
          { required: true, message: "社保和公积金费用必填", trigger: "change" },
          { pattern: /^\d+(\.\d+)?$/, message: "必须为大于等于0的数字", trigger: "blur" },
        ],
        socialCost: [
          { required: true, message: "社保成本必填", trigger: "change" },
          { pattern: /^\d+(\.\d+)?$/, message: "必须为大于等于0的数字", trigger: "blur" },
        ],
        accCost: [
          { required: true, message: "公积金成本必填", trigger: "change" },
          { pattern: /^\d+(\.\d+)?$/, message: "必须为大于等于0的数字", trigger: "blur" },
        ],
        socialAndAccCost: [
          { required: true, message: "社保和公积金成本必填", trigger: "change" },
          { pattern: /^\d+(\.\d+)?$/, message: "必须为大于等于0的数字", trigger: "blur" },
        ],
      },
      activeCard: "正常",

      savingTemplate: false, // 保存
      fanyouStorageDialog: false,
      BranchesDialog:false,
      fanyouStorageFilterForm: {
        Name: "",
      },
      Data: [],
      //网点
      BranchesFilterForm: {
        name: "",
        id:""
      },
      BranchName:"",
      BranchesData: [],
      SelectedBindBranch:[],
      BranchesTableLoading: false,
      BranchespageSize:10,
      BranchesCurrentPage: 1,
      BranchesTotalCount: 0,
      linkingTotable: false,
    };
  },
  methods: {
    //保存提交表单
    SubmitForm() {
      let validRes = [];
      this.$refs["basicInfoForm"].validate((valid) => {
        validRes.push(valid);
      });
      let res = validRes.every((item) => {
        return item;
      });
      if (res) {
        this.saveCostTemplate();
      } else {
        console.log("error submit!!");
      }
    },
    selectTime(val){
      console.log(val);
      this.UnSelectedList = [];
      this.basicInfoForm.branchesName ="";
      this.basicInfoForm.branches ="";
      this.$refs.BranchesTable.clearSelection();
    },
    //取消按钮
    returnGeneralList(){
      this.$router.push("/CostTemplateManage");
    },
    async saveCostTemplate() {
      // 保存费用模板
      let params = this.basicInfoForm;
      if(this.basicInfoForm.type==2 && this.basicInfoForm.branchesName=="")
      {
        this.$message.error("自定义网点模板必须绑定关联网点");
        return;
      }
       this.savingTemplate = true;
      console.log(params);
      try {
        let res = await this.$api.BillManage.AddCostTemplate(params);
        if (res.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          this.savingTemplate = false;
          this.returnGeneralList();
        } else {
          this.savingTemplate = false;
          this.$message.error(res.msg);
        }
      } catch (e) {
        console.log(e);
        this.$message.error(e.data.msg);
        this.savingTemplate = false;
      }
    },
    async getUnselect(effectMonth){
      try{
       let resG = await this.$api.BillManage.getSelectedBranches(effectMonth,0);
       if(resG)
       {
        if(resG.data != null){
          this.UnSelectedList = resG.data.map(function(o) {return o.branchName;});
          console.log(this.UnSelectedList);
        }
        console.log("获取已被绑定网点");
        console.log(this.UnSelectedList);
       }else{
        this.$message.error("获取已被绑定网点失败！");
       }
      }catch(e){
        this.$message.error("获取已被绑定网点失败1！");
      }
    },
    //选择模板类型
    typeChange(type) {
      this.SetName(type);
    },
    //获取模板名称
    async SetName(type) {
      this.GetcheckType();
      if(type ==0||type ==1){
        try {
          let res = await this.$api.BillManage.IsExistType(type);
          if(!res){
            return;    
          }
        } catch (e) {
          console.log(e);
          this.$message.error(e.data.msg);
        }
      }
      if(type == 0){
        this.basicInfoForm.name = "开户通用模板";
      }
      else if(type == 1){
        this.basicInfoForm.name = "托管通用模板";
      }else if(type==2){
        //获取最新的自定义网点托管模板名称
        try {
          let req = await this.$api.BillManage.getTemplateName();
          console.log(req);
          if (req.code == 200) {
            this.$message({
              message: "获取最新自定义网点托管模板名称成功",
              type: "success",
            });
            this.basicInfoForm.name = req.data;
          } else {
            this.$message.error(req.msg);
          }
        } catch (e) {
          console.log(e);
          this.$message.error("获取模板名称失败");
        }
      }
    },
    GetcheckType(){
      if(this.basicInfoForm.type==2){
        this.checkType = true;
      }
      else{
        this.checkType = false;
      }
    },
    //关于网点的方法
    BranchesCurrentChange(currentPage) {
      // 当前页码改变
      this.BranchesCurrentPage = currentPage;
      this.getBranchesList();
    },
    ChooseBranches() {
      this.getBranchesList();
    },
    searchBranches(){
      this.BranchespageSize = 10;
      this.BranchesCurrentPage = 1;
      this.getBranchesList();
    },
     //初始化是否禁用
    initSeleTable(row){
       if(this.UnSelectedList.includes(row.name)) {
        return  false
      }  else { 
        return  true
      }
    },
    //获取网点信息
    async getBranchesList(){
      // try {
      //   //获取所有已被绑定的网点数据
        
      //   let rep = await this.$api.BillManage.getSelectedBranches(0);
      //   console.log(rep);
      //   this.SelectedBindBranch = rep.data;
      // } catch (e) {
      //   this.$message.error("获取已被绑定的网点数据失败");
      // }
      let validRes = [];
      this.$refs["basicInfoForm"].validate((valid) => {
        validRes.push(valid);
      });
      let effectMonth = this.basicInfoForm.effectMonth;
       if(effectMonth==""||effectMonth==new Date()){
        this.$message.error("请先选择生效月份，再进行网点绑定");
        return;
       }
      this.getUnselect(effectMonth);
       let params = {
        pageCount: this.BranchesCurrentPage,
        takeCount: this.BranchespageSize,
        branchName: this.BranchName,
        auditStatus:1,
      };
      console.log(this.BranchName);
      console.log(params);
      this.BranchestableLoading = true;
      try {
        let res = await this.$api.branch.BranchList(params);
        
        if (res) {
          this.BranchesTotalCount = res.totalCount;
          this.BranchesData = res.item;
          this.BranchesDialog = true;
        } else {
          console.log("empty data");
          this.BranchesDialog = false;
        }
        this.BranchestableLoading = false;
      } catch (e) {
        console.log(e);
        this.BranchestableLoading = false;
        this.BranchesDialog = false;
      }
    },
    getBranchesSelected(){
      let branchSelectList = this.$refs["BranchesTable"].selection;
      console.log(branchSelectList);
      this.BranchesDialog = false;
      let nameStr = "";
      let ids = ""
      branchSelectList.forEach(br => {
        if(nameStr > ""){
          nameStr +=";"+br.name; 

        }else{
          nameStr =br.name; 
        }
        if(ids > ""){
          ids +=";"+br.id; 

        }else{
          ids =br.id; 
        }
      });
      this.basicInfoForm.branchesName = nameStr;
      this.basicInfoForm.branches = ids;
    },
    getRowKeys(row) {
      return row.id;
    },
  },
  mounted() {
  },
};
</script>


<style src="@/assets/public.css"></style>
<style scoped>
#addGeneralPolicy {
  padding: 20px;
}
.basic-info {
  width: 100%;
  font-size: 14px;
  background: #fafafa;
  padding: 0 20px;
  border-radius: 2px 2px 0 0;
  border-bottom: 1px solid #ededed;
}
.el-collapse {
  border-top: 0;
}
.basic-info-form {
  padding: 20px 20px 0;
}
.basic-info-form .el-select {
  width: 100%;
}
.basic-info-form .el-date-editor {
  width: 100%;
}
.policy-info {
  margin: 20px 0 0;
}
.control-panel {
  width: 100%;
  height: 48px;
  background: #fafafa;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px solid #ededed;
  box-sizing: border-box;
}
.uploadfileName {
  margin-left: 8px;
}
</style>
