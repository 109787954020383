<template>
    <div id="AddHosting">
        <div class="page-header">
            <p class="page-path">
                业务管理<span class="slashline">/</span>托管业务申报列表<span class="slashline">/</span>新增托管
            </p>
            <p class="page-title">新增托管</p>
        </div>
        <div class="operation-pannel">
            <el-collapse v-model="activeNames">
                <el-collapse-item name="1">
                    <template slot="title">
                        <div class="basic-info">单位基本信息</div>
                    </template>
                    <el-form :model="basicInfoForm"
                             label-position="top"
                             ref="basicInfoForm"
                             class="basic-info-form"
                             :rules="basicInfoFormRules">
                        <el-row :gutter="30">
                            <el-col :span="8">
                                <el-form-item label="单位名称" prop="enterpriseName">
                                    <el-input v-model="basicInfoForm.enterpriseName"
                                              placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="单位编号" prop="enterpriseId">
                                    <el-input v-model="basicInfoForm.enterpriseId"
                                              placeholder=""
                                              :disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="所属客户" prop="customer">
                                    <el-select v-model="basicInfoForm.customer"
                                               placeholder="请选择"
                                               disabled>
                                        <el-option label="ERS" value="KH100010"></el-option>
                                        <el-option label="FAR" value="KH100012"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="30">
                            <el-col :span="8">
                                <el-form-item label="托管类型" prop="entrustType">
                                    <el-select v-model="basicInfoForm.entrustType"
                                               placeholder="请选择"
                                               multiple>
                                        <el-option label="社保" :value="0"></el-option>
                                        <el-option label="公积金" :value="1"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="托管网点" prop="branch">
                                    <el-select v-model="basicInfoForm.branch" filterable
                                               placeholder="请选择">
                                        <el-option v-for="item in branchList"
                                                   :label="item.lable"
                                                   :value="item.value"
                                                   :key="item.value"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="30">
                            <el-col :span="8">
                                <el-form-item label="开始托管时间" prop="startTime">
                                    <el-date-picker :editable="false"
                                                    v-model="basicInfoForm.startTime"
                                                    type="date"
                                                    value-format="yyyy-MM-dd"
                                                    placeholder="请选择"
                                                    :disabled="true">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="结束托管时间" prop="endTime">
                                    <el-date-picker :editable="false"
                                                    v-model="basicInfoForm.endTime"
                                                    value-format="yyyy-MM"
                                                    type="month"
                                                    placeholder=""
                                                    :disabled="true">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
                <el-collapse-item name="3"
                                  v-if="basicInfoForm.entrustType.indexOf(0) != -1">
                    <template slot="title">
                        <div class="basic-info">社保信息</div>
                    </template>
                    <el-form :model="socialSecurityForm"
                             label-position="right"
                             label-width="180px"
                             ref="socialSecurityForm"
                             class="basic-info-form"
                             :rules="socialSecurityFormRules">
                        <el-row :gutter="30"
                                v-for="(item, index) in socialSecurityForm.entrustForm.info"
                                :key="index">
                            <el-col :span="7">
                                <el-form-item label="Ukey："
                                              :prop="'entrustForm.info.' + index + '.ukey'"
                                              :rules="[
                    {
                      required: true,
                      message: '请输入Ukey',
                      trigger: 'blur',
                    },
                  ]">
                                    <el-input v-model="item.ukey" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7">
                                <el-form-item label="账号："
                                              :prop="'entrustForm.info.' + index + '.account'"
                                              :rules="[
                    { required: true, message: '请输入账号', trigger: 'blur' },
                  ]">
                                    <el-input v-model="item.account"
                                              placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7">
                                <el-form-item label="密码："
                                              :prop="'entrustForm.info.' + index + '.password'"
                                              :rules="[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                  ]">
                                    <el-input v-model="item.password"
                                              placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="2">
                                <el-button type="danger"
                                           icon="el-icon-delete"
                                           circle
                                           @click="delEntrustInfo(index, 'socialSecurityForm')"></el-button>
                                <el-button v-if="index === 0"
                                           type="primary"
                                           icon="el-icon-plus"
                                           circle
                                           @click="addEntrustInfo('socialSecurityForm')"></el-button>
                            </el-col>
                        </el-row>
                        <el-row :gutter="30">
                            <el-col :span="7">
                                <el-form-item label="社保出账日：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="billMonth">
                                            <el-select v-model="socialSecurityForm.billMonth"
                                                       placeholder="请选择"
                                                       style="width: 104px">
                                                <el-option label="当月" value="当月"></el-option>
                                                <el-option label="次月" value="次月"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item prop="billDay">
                                            <el-select v-model="socialSecurityForm.billDay"
                                                       placeholder="请选择"
                                                       style="width: 104px; margin-left: 4px">
                                                <el-option v-for="(item, index) in 31"
                                                           :key="index"
                                                           :label="item"
                                                           :value="item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7" :offset="7">
                                <el-form-item label="社保扣款日：" prop="deductDate">
                                    <el-select v-model="socialSecurityForm.deductDate"
                                               placeholder="请选择">
                                        <el-option v-for="(item, index) in 31"
                                                   :key="index"
                                                   :label="item"
                                                   :value="item"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="30">
                            <el-col :span="7">
                                <el-form-item label="增员截止日(社保)：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="addDeadlineMonth">
                                            <el-select v-model="socialSecurityForm.addDeadlineMonth"
                                                       placeholder="请选择"
                                                       style="width: 104px">
                                                <el-option label="当月" value="当月"></el-option>
                                                <el-option label="上月" value="上月"></el-option>
                                                <el-option label="次月" value="次月"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item prop="addDeadlineDay">
                                            <el-select v-model="socialSecurityForm.addDeadlineDay"
                                                       placeholder="请选择"
                                                       style="width: 104px; margin-left: 4px">
                                                <el-option v-for="(item, index) in 31"
                                                           :key="index"
                                                           :label="item"
                                                           :value="item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7" :offset="7">
                                <el-form-item label="减员截止日(社保)：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="minusDeadlineMonth">
                                            <el-select v-model="socialSecurityForm.minusDeadlineMonth"
                                                       placeholder="请选择"
                                                       style="width: 104px">
                                                <el-option label="当月" value="当月"></el-option>
                                                <el-option label="上月" value="上月"></el-option>
                                                <el-option label="次月" value="次月"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item prop="minusDeadlineDay">
                                            <el-select v-model="socialSecurityForm.minusDeadlineDay"
                                                       placeholder="请选择"
                                                       style="width: 104px; margin-left: 4px">
                                                <el-option v-for="(item, index) in 31"
                                                           :key="index"
                                                           :label="item"
                                                           :value="item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
                <el-collapse-item name="4"
                                  v-if="basicInfoForm.entrustType.indexOf(1) != -1">
                    <template slot="title">
                        <div class="basic-info">公积金政策</div>
                    </template>
                    <el-form :model="providentFundForm"
                             label-position="right"
                             label-width="180px"
                             ref="providentFundForm"
                             class="basic-info-form"
                             :rules="providentFundFormRules">
                        <el-row :gutter="30"
                                v-for="(item, index) in providentFundForm.entrustForm.info"
                                :key="index">
                            <el-col :span="7">
                                <el-form-item label="Ukey："
                                              :prop="'entrustForm.info.' + index + '.ukey'"
                                              :rules="[
                    {
                      required: true,
                      message: '请输入Ukey',
                      trigger: 'blur',
                    },
                  ]">
                                    <el-input v-model="item.ukey" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7">
                                <el-form-item label="账号："
                                              :prop="'entrustForm.info.' + index + '.account'"
                                              :rules="[
                    { required: true, message: '请输入账号', trigger: 'blur' },
                  ]">
                                    <el-input v-model="item.account"
                                              placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7">
                                <el-form-item label="密码："
                                              :prop="'entrustForm.info.' + index + '.password'"
                                              :rules="[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                  ]">
                                    <el-input v-model="item.password"
                                              placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="2">
                                <el-button type="danger"
                                           icon="el-icon-delete"
                                           circle
                                           @click="delEntrustInfo(index, 'providentFundForm')"></el-button>
                                <el-button v-if="index === 0"
                                           type="primary"
                                           icon="el-icon-plus"
                                           circle
                                           @click="addEntrustInfo('providentFundForm')"></el-button>
                            </el-col>
                        </el-row>
                        <el-row :gutter="30">
                            <el-col :span="7">
                                <el-form-item label="公积金出账日：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="billMonth">
                                            <el-select v-model="providentFundForm.billMonth"
                                                       placeholder="请选择"
                                                       style="width: 104px">
                                                <el-option label="当月" value="当月"></el-option>
                                                <el-option label="次月" value="次月"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item prop="billDay">
                                            <el-select v-model="providentFundForm.billDay"
                                                       placeholder="请选择"
                                                       style="width: 104px; margin-left: 4px">
                                                <el-option v-for="(item, index) in 31"
                                                           :key="index"
                                                           :label="item"
                                                           :value="item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7" :offset="7">
                                <el-form-item label="公积金扣款日：" prop="deductDate">
                                    <el-select v-model="providentFundForm.deductDate"
                                               placeholder="请选择">
                                        <el-option v-for="(item, index) in 31"
                                                   :key="index"
                                                   :label="item"
                                                   :value="item"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="30">
                            <el-col :span="7">
                                <el-form-item label="增员截止日(公积金)：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="addDeadlineMonth">
                                            <el-select v-model="providentFundForm.addDeadlineMonth"
                                                       placeholder="请选择"
                                                       style="width: 104px">
                                                <el-option label="当月" value="当月"></el-option>
                                                <el-option label="上月" value="上月"></el-option>
                                                <el-option label="次月" value="次月"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item prop="addDeadlineDay">
                                            <el-select v-model="providentFundForm.addDeadlineDay"
                                                       placeholder="请选择"
                                                       style="width: 104px; margin-left: 4px">
                                                <el-option v-for="(item, index) in 31"
                                                           :key="index"
                                                           :label="item"
                                                           :value="item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7" :offset="7">
                                <el-form-item label="减员截止日(公积金)：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="minusDeadlineMonth">
                                            <el-select v-model="providentFundForm.minusDeadlineMonth"
                                                       placeholder="请选择"
                                                       style="width: 104px">
                                                <el-option label="当月" value="当月"></el-option>
                                                <el-option label="上月" value="上月"></el-option>
                                                <el-option label="次月" value="次月"></el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item prop="minusDeadlineDay">
                                            <el-select v-model="providentFundForm.minusDeadlineDay"
                                                       placeholder="请选择"
                                                       style="width: 104px; margin-left: 4px">
                                                <el-option v-for="(item, index) in 31"
                                                           :key="index"
                                                           :label="item"
                                                           :value="item"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="30">
                            <el-col :span="7">
                                <el-form-item label="单位缴费比例：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="enterprisePercent">
                                            <el-input v-model="providentFundForm.enterprisePercent"
                                                      placeholder="单位比例"
                                                      style="width: 208px">
                                                <template slot="append">
                                                    %
                                                </template>
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7">
                                <el-form-item label="个人缴费比例：" required>
                                    <div class="form-inline-group">
                                        <el-form-item prop="personPercent">
                                            <el-input v-model="providentFundForm.personPercent"
                                                      placeholder="个人比例"
                                                      style="width: 208px; margin-left: 4px">
                                                <template slot="append">
                                                    %
                                                </template>
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="bottom-btn-group">
            <el-button @click="returnApplyList">返回</el-button>
            <el-button type="primary"
                       @click="submitForms"
                       :loading="submitFormsLoading">提交</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AddHosting",
        components: {},
        data: function () {
            return {
                activeNames: ["1", "2", "3", "4"],
                branchList: [],
                basicInfoForm: {
                    enterpriseName: "",
                    enterpriseId: "",
                    customer: "KH100012",
                    entrustType: [],
                    branch: "",
                    startTime: new Date(),
                    endTime: "",
                },
                basicInfoFormRules: {
                    enterpriseName: [
                        { required: true, message: "请输入单位名称", trigger: "blur" },
                    ],
                    enterpriseId: [
                        { required: true, message: "单位编号不能为空", trigger: "blur" },
                    ],
                    customer: [
                        { required: true, message: "请选择所属客户", trigger: "change" },
                    ],
                    entrustType: [
                        { required: true, message: "请选择托管类型", trigger: "change" },
                    ],
                    branch: [
                        { required: true, message: "请选择托管网点", trigger: "change" },
                    ],
                    startTime: [
                        { required: true, message: "请选择开始托管时间", trigger: "change" },
                    ],
                },
                socialSecurityForm: {
                    // 社保信息
                    entrustType: 0,
                    billMonth: "",
                    billDay: "",
                    deductDate: "",
                    addDeadlineMonth: "",
                    addDeadlineDay: "",
                    minusDeadlineMonth: "",
                    minusDeadlineDay: "",
                    enterprisePercent: "",
                    personPercent: "",
                    entrustForm: {
                        // 托管资料
                        info: [
                            {
                                entrustId: 0,
                                ukey: "",
                                account: "",
                                password: "",
                            },
                        ],
                    },
                },
                socialSecurityFormRules: {
                    billMonth: [{ required: true, message: "请选择", trigger: "change" }],
                    billDay: [{ required: true, message: "请选择", trigger: "change" }],
                    deductDate: [{ required: true, message: "请选择", trigger: "change" }],
                    addDeadlineMonth: [
                        { required: true, message: "请选择", trigger: "change" },
                    ],
                    addDeadlineDay: [
                        { required: true, message: "请选择", trigger: "change" },
                    ],
                    minusDeadlineMonth: [
                        { required: true, message: "请选择", trigger: "change" },
                    ],
                    minusDeadlineDay: [
                        { required: true, message: "请选择", trigger: "change" },
                    ],
                },
                providentFundForm: {
                    // 社保信息
                    entrustType: 1,
                    billMonth: "",
                    billDay: "",
                    deductDate: "",
                    addDeadlineMonth: "",
                    addDeadlineDay: "",
                    minusDeadlineMonth: "",
                    minusDeadlineDay: "",
                    enterprisePercent: "",
                    personPercent: "",
                    entrustForm: {
                        // 托管资料
                        info: [
                            {
                                entrustId: 0,
                                ukey: "",
                                account: "",
                                password: "",
                            },
                        ],
                    },
                },
                providentFundFormRules: {
                    billMonth: [{ required: true, message: "请选择", trigger: "change" }],
                    billDay: [{ required: true, message: "请选择", trigger: "change" }],
                    deductDate: [{ required: true, message: "请选择", trigger: "change" }],
                    addDeadlineMonth: [
                        { required: true, message: "请选择", trigger: "change" },
                    ],
                    addDeadlineDay: [
                        { required: true, message: "请选择", trigger: "change" },
                    ],
                    minusDeadlineMonth: [
                        { required: true, message: "请选择", trigger: "change" },
                    ],
                    minusDeadlineDay: [
                        { required: true, message: "请选择", trigger: "change" },
                    ],
                    enterprisePercent: [
                        { required: true, message: "请输入单位比例", trigger: "change" },
                    ],
                    personPercent: [
                        { required: true, message: "请输入个人比例", trigger: "change" },
                    ],
                },
                submitFormsLoading: false,
            };
        },
        methods: {
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        return true;
                    } else {
                        console.log("Form:" + formName + "error submit!!");
                        return false;
                    }
                });
            },
            addEntrustInfo(formName) {
                if (formName == "socialSecurityForm") {
                    this.socialSecurityForm.entrustForm.info.push({
                        entrustId: 0,
                        ukey: "",
                        account: "",
                        password: "",
                    });
                } else {
                    this.providentFundForm.entrustForm.info.push({
                        entrustId: 0,
                        ukey: "",
                        account: "",
                        password: "",
                    });
                }
            },
            delEntrustInfo(index, formName) {
                if (index === 0) {
                    return;
                }
                if (formName == "socialSecurityForm") {
                    this.socialSecurityForm.entrustForm.info.splice(index, 1);
                } else {
                    this.providentFundForm.entrustForm.info.splice(index, 1);
                }
            },
            returnApplyList() {
                this.$router.push({
                    name: "HostingApplyList",
                });
            },
            submitForms() {
                let validRes = [];
                this.$refs.basicInfoForm.validate((valid) => {
                    validRes.push(valid);
                });
                if (this.getentrustType() == 0 || this.getentrustType() == 2) {
                    this.$refs.socialSecurityForm.validate((valid) => {
                        validRes.push(valid);
                    });
                }
                if (this.getentrustType() == 1 || this.getentrustType() == 2) {
                    this.$refs.providentFundForm.validate((valid) => {
                        validRes.push(valid);
                    });
                }
                let res = validRes.every((item) => {
                    return item;
                });
                if (res) {
                    this.createEntrust();
                } else {
                    console.log("error submit!!");
                }
            },
            getentrustType() {
                if (
                    this.basicInfoForm.entrustType.indexOf(0) != -1 &&
                    this.basicInfoForm.entrustType.indexOf(1) == -1
                ) {
                    return 0;
                } else if (
                    this.basicInfoForm.entrustType.indexOf(0) == -1 &&
                    this.basicInfoForm.entrustType.indexOf(1) != -1
                ) {
                    return 1;
                } else if (
                    this.basicInfoForm.entrustType.indexOf(0) != -1 &&
                    this.basicInfoForm.entrustType.indexOf(1) != -1
                ) {
                    return 2;
                }
            },
            async nextId() {
                try {
                    let res = await this.$api.entrustOrder.nextId();
                    if (res) {
                        this.basicInfoForm.enterpriseId = res;
                    } else {
                        this.$message.error("生成单位编号出错！");
                    }
                } catch (e) {
                    console.log(e);
                    this.$message.error("生成单位编号出错！");
                }
            },
            async createEntrust() {
                let entrustDates = [];
                let entrustUkeys = [];
                let entrustType = "";
                let entrustDatesFit = [];
                // 勾选托管类型为社保
                if (this.basicInfoForm.entrustType.indexOf(0) != -1) {
                    entrustDates.push(this.socialSecurityForm);
                }
                // 勾选托管类型为公积金
                if (this.basicInfoForm.entrustType.indexOf(1) != -1) {
                    entrustDates.push(this.providentFundForm);
                }
                entrustDates.forEach((entrustDate) => {
                    let newObj = {};
                    for (let i in entrustDate) {
                        if (i != "entrustForm") {
                            newObj[i] = entrustDate[i];
                        }
                    }
                    entrustDatesFit.push(newObj);
                });
                if (this.basicInfoForm.entrustType.indexOf(0) != -1) {
                    this.socialSecurityForm.entrustForm.info.forEach((item) => {
                        entrustUkeys.push({
                            entrustId: 0,
                            entrustType: 0,
                            ukey: item.ukey,
                            account: item.account,
                            password: item.password,
                        });
                    });
                }
                if (this.basicInfoForm.entrustType.indexOf(1) != -1) {
                    this.providentFundForm.entrustForm.info.forEach((item) => {
                        entrustUkeys.push({
                            entrustId: 0,
                            entrustType: 1,
                            ukey: item.ukey,
                            account: item.account,
                            password: item.password,
                        });
                    });
                }
                entrustType = this.getentrustType();
                let params = {
                    entrustType: entrustType,
                    enterpriseName: this.basicInfoForm.enterpriseName,
                    enterpriseId: this.basicInfoForm.enterpriseId,
                    customerId: this.basicInfoForm.customer,
                    branchId: this.basicInfoForm.branch,
                    beginTime: this.basicInfoForm.startTime,
                    endTime: this.basicInfoForm.endTime,
                    entrustUkeys: entrustUkeys,
                    entrustDates: entrustDatesFit,
                };
                this.submitFormsLoading = true;
                try {
                    let res = await this.$api.entrustOrder.create(params);
                    if (res) {
                        this.$message({
                            message: "新增托管成功！",
                            type: "success",
                        });
                        this.submitFormsLoading = false;
                        this.returnApplyList();
                    } else {
                        this.$message.error("新增失败：" + res.msg);
                        this.submitFormsLoading = false;
                    }
                } catch (e) {
                    console.log(e);
                    let errMsg = e.data.msg ? e.data.msg : e.msg;
                    this.$message.error("新增失败：" + errMsg);
                    this.submitFormsLoading = false;
                }
            },
            async getBranches() {
                try {
                    let res = await this.$api.publicapi.branches();
                    if (res) {
                        this.branchList = res;
                    }
                } catch (e) {
                    console.log(e);
                }
            },
        },
        mounted() {
            this.nextId();
            this.getBranches();
        },
    };
</script>


<style src="@/assets/public.css"></style>
<style scoped>
    #AddHosting {
        padding: 20px;
    }

    .basic-info {
        width: 100%;
        font-size: 14px;
        background: #fafafa;
        padding: 0 20px;
        border-radius: 2px 2px 0 0;
        border-bottom: 1px solid #ededed;
    }

    .basic-info-form {
        padding: 20px 20px 0;
    }

    .form-inline-group .el-form-item {
        display: inline-block;
        margin-bottom: 0;
    }

    .bottom-btn-group {
        display: block;
        margin: 0 auto;
        text-align: center;
    }
</style>
